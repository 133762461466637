/* eslint-disable global-require */

// The top-level (parent) route
import _ from 'lodash';
import queryString from 'query-string';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { loadAuth } from '../actions/auth';
import {
  getMissingRequiredProfileFields,
  getWhitelabeledOrganizationSlug,
  isUserFromWhitelabeledOrganization,
  isIntegrationPersonIdVerificationEnabled,
  isIntegrationPersonIdValid,
  isTwoFaSetupRequired,
} from '../helpers';
import isLoggedIn from '../helpers/checkAuth';

const initialRoutes = [
  // The home route is added to client.js to make sure shared components are
  // added to client.js as well and not repeated in individual each route chunk.
  {
    path: '/:lang',
    load: () => import(/* webpackChunkName: 'home' */ './courses'),
  },
  {
    path: '/:lang/edit',
    children: [
      {
        path: '/courses',
        children: [
          {
            path: '/new',
            load: () =>
              import(/* webpackChunkName: 'edit-course' */ './editCourse'),
          },
          {
            path: '/:courseId',
            load: () =>
              import(/* webpackChunkName: 'edit-course' */ './editCourse'),
          },
        ],
      },
    ],
  },
  {
    path: '/:lang/tags',
    load: () => import(/* webpackChunkName: 'admin-tags' */ './tags'),
  },
  {
    path: '/:lang/authors',
    load: () => import(/* webpackChunkName: 'admin-authors' */ './authors'),
  },
  {
    path: '/:lang/users',
    load: () => import(/* webpackChunkName: 'admin-users' */ './users'),
  },
  {
    path: '/:lang/groups',
    load: () => import(/* webpackChunkName: 'admin-users' */ './mainGroups'),
  },
  {
    path: '/:lang/groups/:mainGroupId',
    load: () => import(/* webpackChunkName: 'admin-users' */ './subgroups'),
  },
  {
    path: '/:lang/groups/:mainGroupId/info',
    load: () => import(/* webpackChunkName: 'admin-users' */ './mainGroupInfo'),
  },
  {
    path: '/:lang/api-keys/:userId',
    load: () => import(/* webpackChunkName: 'admin-users' */ './apiKeys'),
  },
  {
    path: '/:lang/quizzes',
    load: () => import(/* webpackChunkName: 'admin-quizzes' */ './quizzes'),
  },
  {
    path: '/:lang/quizzes/:quizId/quiz-result',
    load: () =>
      import(
        /* webpackChunkName: 'student-view-quiz' */ './quizzes/quizResult'
      ),
  },
  {
    path: '/:lang/questions',
    load: () =>
      import(/* webpackChunkName: 'admin-questions' */ './quizzes/questions'),
  },
  {
    path: '/:lang/courses/:courseSlug',
    load: () =>
      import(
        /* webpackChunkName: 'course-details' */ './courseDetails/CourseDetailsContent/index'
      ),
  },
  {
    path: '/:lang/courses/:courseSlug/discussions',
    load: () =>
      import(
        /* webpackChunkName: 'course-details' */ './courseDetails/CourseDetailsDiscussions/index'
      ),
  },
  {
    path: '/:lang/courses/:courseSlug/discussions/:discussionId',
    load: () =>
      import(
        /* webpackChunkName: 'course-details' */ './courseDetails/CourseDetailsDiscussions/CourseDetailsDiscussion/index'
      ),
  },
  {
    path: '/:lang/classroom/group-discussions',
    load: () =>
      import(
        /* webpackChunkName: 'classroom' */ './classroom/GroupDiscussions/index'
      ),
  },
  {
    path: '/:lang/classroom/group-discussions/:discussionId',
    load: () =>
      import(
        /* webpackChunkName: 'classroom' */ './classroom/GroupDiscussions/GroupDiscussion/index'
      ),
  },
  {
    path: '/:lang/classroom/followed-discussions',
    load: () =>
      import(
        /* webpackChunkName: 'classroom' */ './classroom/FollowedDiscussions/index'
      ),
  },
  {
    path: '/:lang/classroom/followed-discussions/:discussionId',
    load: () =>
      import(
        /* webpackChunkName: 'classroom' */ './classroom/FollowedDiscussions/FollowedDiscussion/index'
      ),
  },
  {
    path: '/:lang/admin-tools',
    load: () => import(/* webpackChunkName: 'admin-tools' */ './admin-tools'),
  },
  {
    path: '/:lang/admin-tools/whitelabeling',
    load: () =>
      import(
        /* webpackChunkName: 'admin-tools' */ './admin-tools/Whitelabeling/index'
      ),
  },
  {
    path: '/:lang/courses/:courseSlug/notes',
    load: () =>
      import(
        /* webpackChunkName: 'course-notes' */ './courseDetails/CourseDetailsNotes/index'
      ),
  },
  {
    path: '/:lang/courses/:courseSlug/lessons/:lessonSlug',
    load: () =>
      import(/* webpackChunkName: 'lesson-details' */ './lessonDetails'),
  },
  {
    path: '/:lang/lessons/:lessonSlug',
    load: () =>
      import(
        /* webpackChunkName: 'lesson-details' */ './lessonDetails/singleLessonAction'
      ),
  },
  {
    path: '/:lang/tasks/:taskId/exams/:quizId',
    load: () =>
      import(
        /* webpackChunkName: 'student-view-quiz' */ './quizzes/studentViewQuiz'
      ),
  },
  {
    path: '/:lang/courses/:courseSlug/quizzes/:quizId',
    load: () =>
      import(
        /* webpackChunkName: 'student-view-quiz' */ './quizzes/studentViewQuiz'
      ),
  },
  {
    path: '/:lang/courses/:courseSlug/quizzes/:quizId/quiz-result',
    load: () =>
      import(
        /* webpackChunkName: 'student-view-quiz' */ './quizzes/quizResult'
      ),
  },
  {
    path: '/:lang/tasks/:taskId/exams/:quizId/quiz-result',
    load: () =>
      import(
        /* webpackChunkName: 'student-view-quiz' */ './quizzes/quizResult'
      ),
  },
  {
    path: '/:lang/profile',
    load: () =>
      import(
        /* webpackChunkName: 'edit-profile' */ './editProfile/Profile/index'
      ),
  },
  {
    path: '/:lang/profile/certificates',
    load: () =>
      import(
        /* webpackChunkName: 'edit-profile' */ './editProfile/Certificates/index'
      ),
  },
  {
    path: '/:lang/profile/settings',
    load: () =>
      import(
        /* webpackChunkName: 'edit-profile' */ './editProfile/Settings/index'
      ),
  },
  {
    path: '/:lang/profile/access',
    load: () =>
      import(
        /* webpackChunkName: 'edit-profile' */ './editProfile/ContinueAccess/index'
      ),
  },
  {
    path: '/:lang/profiles/:profileId',
    load: () => import(/* webpackChunkName: 'view-profile' */ './viewProfile'),
  },
  {
    path: '/:lang/cert/:code',
    load: () => import(/* webpackChunkName: 'cert' */ './cert'),
  },
  {
    path: '/:lang/document/:code',
    load: () => import(/* webpackChunkName: 'document' */ './document'),
  },
  // {
  //   path: '/:lang/statistics/my',
  //   load: () =>
  //     import(
  //       /* webpackChunkName: 'statistics' */ './statistics/MyStatistics/index'
  //     ),
  // },
  {
    path: '/:lang/statistics/courses',
    load: () =>
      import(
        /* webpackChunkName: 'statistics' */ './statistics/CourseStatistics/index'
      ),
  },
  {
    path: '/:lang/statistics/group',
    load: () =>
      import(
        /* webpackChunkName: 'group-statistics' */ './statistics/GroupStatistics/index'
      ),
  },
  {
    path: '/:lang/statistics/quizzes',
    load: () =>
      import(
        /* webpackChunkName: 'statistics' */ './statistics/QuizStatistics/index'
      ),
  },
  {
    path: '/:lang/statistics/assignments',
    load: () =>
      import(
        /* webpackChunkName: 'assignment-statistics' */ './statistics/AssignmentStatistics/index'
      ),
  },
  {
    path: '/:lang/statistics/demonstrations',
    load: () =>
      import(
        /* webpackChunkName: 'demonstration-statistics' */ './statistics/DemonstrationStatistics/index'
      ),
  },
  {
    path: '/:lang/statistics/users-activity',
    load: () =>
      import(
        /* webpackChunkName: 'statistics' */ './statistics/UsersActivityStatistics/index'
      ),
  },
  {
    path: '/:lang/tasks',
    load: () => import(/* webpackChunkName: 'tasks' */ './tasks/myTasks'),
  },
  {
    path: '/:lang/group-tasks',
    load: () => import(/* webpackChunkName: 'tasks' */ './tasks/groupTasks'),
  },
  {
    path: '/:lang/group-tasks/:taskId/results',
    load: () => import(/* webpackChunkName: 'tasks' */ './tasks/tasksResults'),
  },
  {
    path: '/:lang/xlsx-export/:id',
    load: () => import(/* webpackChunkName: 'xlsx-export' */ './xlsx-export'),
  },
  {
    path: '/:lang/xlsx-export/course-reviews/:id',
    load: () =>
      import(
        /* webpackChunkName: 'xlsx-export-course-reviews' */ './xlsx-export-course-reviews'
      ),
  },
  {
    path: '/:lang/trial-expired/:mainGroupId',
    load: () =>
      import(/* webpackChunkName: 'trial-expired' */ './trialExpired'),
  },
  {
    path: '/:lang/document-forms',
    load: () =>
      import(/* webpackChunkName: 'document-forms' */ './documentForms/index'),
  },
  {
    path: '/:lang/demonstrations',
    load: () =>
      import(/* webpackChunkName: 'demonstrations' */ './demonstrations/index'),
  },
];

const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './courses'),
    },

    ...initialRoutes,

    ..._.map(initialRoutes, route => ({
      ...route,
      path: route.path.replace('/:lang', '/:lang/:organizationSlug'),
    })),

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import('./not-found'),
    },
  ],

  async action(context) {
    const {
      store,
      fetch,
      next,
      pathname,
      intl: { locale },
      query,
      organizationSlug,
      whitelabeling,
    } = context;

    store.dispatch(showLoading());
    if (context.token) {
      await store.dispatch(loadAuth(fetch));
    }

    const { auth } = store.getState();

    const realOrganizationSlug = isUserFromWhitelabeledOrganization(auth)
      ? getWhitelabeledOrganizationSlug(auth)
      : undefined;

    if (isLoggedIn(auth) && realOrganizationSlug !== organizationSlug) {
      store.dispatch(hideLoading());

      if (['joinMainGroup', 'register'].includes(_.get(query, 'showDialog'))) {
        return {
          redirect: `/${locale}${
            realOrganizationSlug ? `/${realOrganizationSlug}` : ''
          }?${queryString.stringify(query)}`,
        };
      }

      if (pathname.includes('/xlsx-export/course-reviews/')) {
        return {
          redirect: `/${locale}${
            realOrganizationSlug ? `/${realOrganizationSlug}` : ''
          }/${pathname.slice(
            pathname.indexOf('xlsx-export/course-reviews/'),
          )}?${queryString.stringify(query)}`,
        };
      }

      if (pathname.includes('/cert/')) {
        const indexOfCert = pathname.indexOf('cert/');
        const cert = pathname.substring(indexOfCert);

        return {
          redirect: `/${locale}${
            realOrganizationSlug ? `/${realOrganizationSlug}` : ''
          }/${cert}`,
        };
      }

      if (!pathname.includes('/cert/')) {
        return {
          redirect: `/${locale}${
            realOrganizationSlug ? `/${realOrganizationSlug}` : ''
          }`,
        };
      }
    }

    if (
      _.get(auth, 'user.stoppedAccess') &&
      pathname !==
        `/${locale}${
          organizationSlug ? `/${organizationSlug}` : ''
        }/profile/access`
    ) {
      store.dispatch(hideLoading());
      return {
        redirect: `/${locale}${
          organizationSlug ? `/${organizationSlug}` : ''
        }/profile/access`,
      };
    }

    const isSettingsPage =
      pathname ===
        `/${locale}${
          organizationSlug ? `/${organizationSlug}` : ''
        }/profile/settings` && _.has(query, 'twoFaSetupRequired');

    if (isTwoFaSetupRequired(auth) && !isSettingsPage) {
      store.dispatch(hideLoading());
      return {
        redirect: `/${locale}${
          organizationSlug ? `/${organizationSlug}` : ''
        }/profile/settings?twoFaSetupRequired`,
      };
    }

    const missingFields = getMissingRequiredProfileFields(auth);

    const isProfilePage =
      pathname ===
        `/${locale}${organizationSlug ? `/${organizationSlug}` : ''}/profile` &&
      _.has(query, 'missingRequiredFields');

    const isWrongIntegrationPersonId =
      isIntegrationPersonIdVerificationEnabled(auth) &&
      !isIntegrationPersonIdValid(auth);

    if (
      (missingFields.length || isWrongIntegrationPersonId) &&
      !isProfilePage
    ) {
      store.dispatch(hideLoading());
      return {
        redirect: `/${locale}${
          organizationSlug ? `/${organizationSlug}` : ''
        }/profile?missingRequiredFields`,
      };
    }

    // Execute each child route until one of them return the result
    const route = await next();

    store.dispatch(hideLoading());

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - ${_.get(
      whitelabeling,
      `serviceName.${locale}`,
      'Skhole',
    )}`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
