// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-no-translate]{translate:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.qbZLx{color:var(--whitelabeling-primary-color);font-size:17px;font-weight:normal}.TscXl{height:100%;overflow-x:hidden;overflow-y:auto;width:100%}.ZI5he{border-radius:4px;margin-top:20px;overflow:initial}.ZI5he::before{border:10px solid rgba(0,0,0,0);border-bottom:15px solid #fff;content:\"\";display:block;position:absolute;right:12px;top:-20px}.n7yvr{height:18px;width:14px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupMenuItemText": "qbZLx",
	"popoverContent": "TscXl",
	"popover": "ZI5he",
	"helpIcon": "n7yvr"
};
module.exports = ___CSS_LOADER_EXPORT___;
