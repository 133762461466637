import {
  GET_DOCUMENT,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAIL,
  RESET_DOCUMENT,
  SET_DOCUMENT,
} from '../constants';

const initialState = {
  loading: false,
  document: {
    subtitles: [],
    name: '',
    mainGroupId: '',
    image: undefined,
    allowViewOrCopy: false,
    fields: [],
    mainGroup: {},
    documentFiles: [],
    certificatesIds: [],
  },
  error: '',
};

export default function editDocument(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENT_SUCCESS: {
      const document = action.result.json;

      return {
        ...state,
        document,
        loading: false,
      };
    }
    case GET_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_DOCUMENT:
      return {
        ...state,
        document: action.document,
      };
    case RESET_DOCUMENT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
