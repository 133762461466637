import {
  EDIT_QUIZ__GET_QUIZ,
  EDIT_QUIZ__GET_QUIZ_SUCCESS,
  EDIT_QUIZ__GET_QUIZ_FAIL,
  RESET_QUIZ,
  SET_QUIZ,
  SET_QUIZ_ASSOCIATED_COURSE,
  EDIT_QUIZ__SET_OPENED_EDITOR,
  EDIT_QUIZ__GET_OPENED_EDITOR,
  EDIT_QUIZ__GET_OPENED_EDITOR_SUCCESS,
  EDIT_QUIZ__GET_OPENED_EDITOR_FAIL,
  EDIT_QUIZ_TAKE_EDIT_CONTROL,
  EDIT_QUIZ_TAKE_EDIT_CONTROL_SUCCESS,
  EDIT_QUIZ_TAKE_EDIT_CONTROL_FAIL,
} from '../constants';

const initialState = {
  loading: false,
  quiz: {
    _id: '',
    access: 'Group',
    quizMode: 'Exam',
    showReport: true,
    showSpecificNumber: false,
    inPercent: false,
    showRestartBtn: true,
    answersInRandom: true,
    questionsInRandom: true,
    timeLimit: 3600,
    questions: [],
    pass: 80,
    allowViewOrCopy: true,
    associatedLessons: [],
    associatedCourses: [],
    showCorrectAnswers: false,
  },
  openedEditor: {},
  loadingOpenedEditor: false,
  error: '',
  resStatusCode: null,
  takingControl: false,
  takenControl: false,
  takingControlError: '',
};

export default function editQuiz(state = initialState, action) {
  switch (action.type) {
    case EDIT_QUIZ__GET_QUIZ:
      return {
        ...state,
        loading: true,
      };
    case EDIT_QUIZ__GET_QUIZ_SUCCESS:
      return {
        ...state,
        quiz: action.result.json,
        loading: false,
        loadingOpenedEditor: true,
      };
    case EDIT_QUIZ__GET_QUIZ_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        resStatusCode: action.status,
      };
    case SET_QUIZ:
      return {
        ...state,
        quiz: action.quiz,
      };
    case SET_QUIZ_ASSOCIATED_COURSE:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          associatedCourses: [action.course],
        },
      };
    case EDIT_QUIZ__GET_OPENED_EDITOR:
      return {
        ...state,
        loadingOpenedEditor: true,
        error: '',
      };

    case EDIT_QUIZ__GET_OPENED_EDITOR_SUCCESS:
      return {
        ...state,
        loadingOpenedEditor: false,
        openedEditor: action.result.status < 400 ? action.result.json : {},
      };

    case EDIT_QUIZ__GET_OPENED_EDITOR_FAIL:
      return {
        ...state,
        loadingOpenedEditor: false,
        error: action.error,
      };

    case EDIT_QUIZ__SET_OPENED_EDITOR:
      return {
        ...state,
        openedEditor: action.openedEditor,
        takenControl: false,
        takingControl: false,
        takingControlError: '',
      };

    case EDIT_QUIZ_TAKE_EDIT_CONTROL:
      return {
        ...state,
        takingControl: true,
        takenControl: false,
        takingControlError: '',
      };

    case EDIT_QUIZ_TAKE_EDIT_CONTROL_SUCCESS:
      return {
        ...state,
        takingControl: false,
        takenControl: true,
      };

    case EDIT_QUIZ_TAKE_EDIT_CONTROL_FAIL:
      return {
        ...state,
        takingControl: false,
        takingControlError: action.error,
      };
    case RESET_QUIZ:
      return initialState;
    default:
      return state;
  }
}
