import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

export default {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  logger: PropTypes.object.isRequired,
  // Integrate Redux
  // ReactIntl
  intl: IntlProvider.childContextTypes.intl,
  cloudfrontUrl: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  cookiePrefix: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string,
  whitelabeling: PropTypes.shape({}),
  tableFilterAutocompleteStyles: PropTypes.shape({}),
  primaryAutocompleteStyles: PropTypes.shape({}),
  vimeoLivestreamUrl: PropTypes.string.isRequired,
  apiVersion: PropTypes.number.isRequired,
  contentLangs: PropTypes.arrayOf(
    PropTypes.shape({
      createDate: PropTypes.string,
      lang: PropTypes.string,
      name: PropTypes.string,
      _id: PropTypes.string,
    }),
  ).isRequired,
  twoFaDisabled: PropTypes.bool.isRequired,
  initWs: PropTypes.func.isRequired,
};
