import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import muiWithStyles from '@material-ui/core/styles/withStyles';
import { Cookies, withCookies } from 'react-cookie';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';

import { Interweave } from 'interweave';
import * as authActions from '../../actions/auth';
import Link from '../Link';

import {
  buildQuery,
  getWhitelabeledOrganizationSlug,
  handleErroredImage,
} from '../../helpers';
import isLoggedIn from '../../helpers/checkAuth';
import formatLivestreamDate, {
  isSameDay,
} from '../../helpers/formatLivestreamDate';
import SkholeIcon02 from '../statelessComponents/Icons/SkholeIcon02';
import SkholeIcon03 from '../statelessComponents/Icons/SkholeIcon03';
import CircularProgressSmall from '../CircularProgressSmall';

import RestrictedAccessModal from '../RestrictedAccessModal/RestrictedAccessModal';

import s from './CourseItem.scss';

const messages = defineMessages({
  hasTaskLabel: {
    id: 'CourseItem.hasTaskLabel',
    defaultMessage: 'Task',
  },
  isFeaturedLabel: {
    id: 'CourseItem.isFeaturedLabel',
    defaultMessage: 'Featured',
    description: 'Featured label displayed on the course item in items list',
  },
  isNewLabel: {
    id: 'CourseItem.isNewLabel',
    defaultMessage: 'New',
    description: 'New label displayed on the course item in items list',
  },
  isCourseLabel: {
    id: 'CourseItem.isCourseLabel',
    defaultMessage: 'Course',
    description: 'Course label displayed on the course item in items list',
  },
  totalLessonsText: {
    id: 'CourseItem.totalLessonsText',
    defaultMessage: 'lessons',
    description:
      'lessons displayed on the course item in total lessons text in items list',
  },
  courseTimeText: {
    id: 'CourseItem.courseTimeText',
    defaultMessage: '({hours} h {minutes} min)',
  },
  courseTimeMinutesText: {
    id: 'CourseItem.courseTimeMinutesText',
    defaultMessage: '({minutes} min)',
  },
  courseIsDownloading: {
    id: 'CourseItem.courseIsDownloading',
    defaultMessage: 'Downloading course "{name}", related lessons and notes...',
  },
  courseIsDownloaded: {
    id: 'CourseItem.courseIsDownloaded',
    defaultMessage: '"{name}" offline access download complete',
  },
  courseIsRemovedFromOffline: {
    id: 'CourseItem.courseIsRemovedFromOffline',
    defaultMessage:
      'Course "{name}", related lessons and notes are removed from offline access',
  },
  browserDoesNotSupportCaching: {
    id: 'CourseItem.browserDoesNotSupportCaching',
    defaultMessage:
      'Your browser does not support offline caching. For better browsing experience, we recommend using Skhole with Google Chrome',
  },
  errorDuringCourseDownloading: {
    id: 'CourseItem.errorDuringCourseDownloading',
    defaultMessage: 'Oops, error happened during updating cache: {error}',
  },
  courseIsDownloadingWarning: {
    id: 'CourseItem.courseIsDownloadingWarning',
    defaultMessage:
      'Course "{name}" is still downloading for offline, are you sure you want to exit?',
  },
  saveForOffline: {
    id: 'CourseItem.saveForOffline',
    defaultMessage: 'Download for offline',
  },
  removeFromOffline: {
    id: 'CourseItem.removeFromOffline',
    defaultMessage: 'Remove from offline',
  },
  noAccess: {
    id: 'CourseItem.noAccess',
    defaultMessage: 'You don’t have access to this content yet',
  },
  waitForCourseDownloading: {
    id: 'CourseItem.waitForCourseDownloading',
    defaultMessage:
      'It’s possible to download only one course at time. Please try again later.',
  },
  addToFavourites: {
    id: 'CourseItem.addToFavourites',
    defaultMessage: 'Add to favourites',
  },
  removeFromFavourites: {
    id: 'CourseItem.removeFromFavourites',
    defaultMessage: 'Remove from favourites',
  },
  temporaryUnavailableOffline: {
    id: 'CourseItem.temporaryUnavailableOffline',
    defaultMessage: 'Downloading is temporarily disabled due to maintenance.',
  },
  pwaIsAboutToBeDisabled: {
    id: 'General.pwaIsAboutToBeDisabled',
    defaultMessage:
      'Removing this course from offline cache temporarily disables offline mode. All pages will reload',
  },
  confirm: {
    id: 'General.confirmDisablePwa',
    defaultMessage: 'Confirm',
  },
  dismiss: {
    id: 'General.dismissDisablePwa',
    defaultMessage: 'Dismiss',
  },
  courseType: {
    id: 'General.courseType',
    defaultMessage: 'Course',
  },
  livestreamType: {
    id: 'General.livestreamType',
    defaultMessage: 'livestream',
  },
  recordingType: {
    id: 'General.recordingType',
    defaultMessage: 'recording',
  },
  podcastType: {
    id: 'General.podcastType',
    defaultMessage: 'podcast',
  },
  streamingStartAt: {
    id: 'General.streamingStartAt',
    defaultMessage: '{start} - {end}',
  },
  streamingNowLive: {
    id: 'General.streamingNowLive',
    defaultMessage: 'live',
  },
});

const styles = theme => ({
  courseProgressRoot: {
    backgroundColor: '#F6F6F7',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  courseProgressBar: {
    backgroundColor: _.get(theme, 'palette.primary.light'),
  },
});

@injectIntl
@withCookies
@muiWithStyles(styles)
@withStyles(s)
@connect(
  state => ({
    auth: state.auth,
    favoriteCourses: state.auth.favoriteCourses,
    lang: state.intl.locale,
    isCourseDownloading: state.online.isCourseDownloading,
  }),
  dispatch =>
    bindActionCreators(
      {
        loadAuth: authActions.loadAuth,
        saveFavoriteCourses: authActions.saveFavoriteCourses,
      },
      dispatch,
    ),
)
export default class CourseItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      isFeatured: PropTypes.bool,
      hasTask: PropTypes.bool,
      isAvailable: PropTypes.bool,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }),
      totalLessons: PropTypes.number,
      progress: PropTypes.number,
      shortDescription: PropTypes.string,
      type: PropTypes.string,
      liveStreamEnd: PropTypes.string,
      liveStreamStart: PropTypes.string,
      isStreamingNow: PropTypes.bool,
      time: PropTypes.number,
      createDate: PropTypes.instanceOf(Date),
      isPublic: PropTypes.bool,
    }).isRequired,
    isCourseFavoriting: PropTypes.bool, // todo load from global state
    auth: PropTypes.object.isRequired, // eslint-disable-line
    favoriteCourses: PropTypes.array.isRequired, // eslint-disable-line
    afterCourseRemovedFromFavorite: PropTypes.func,
    disableHover: PropTypes.bool,
    className: PropTypes.string,
    isInDashboard: PropTypes.bool,
    lang: PropTypes.string.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    loadAuth: PropTypes.func.isRequired,
    toggleCourseIsDownloading: PropTypes.func.isRequired,
    isCourseDownloading: PropTypes.bool.isRequired,
    inOneColumn: PropTypes.bool.isRequired,
    saveFavoriteCourses: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    classes: PropTypes.shape({
      courseProgressRoot: PropTypes.object, // eslint-disable-line
      courseProgressBar: PropTypes.object, // eslint-disable-line
    }).isRequired,
    searchSessionId: PropTypes.string,
  };

  static defaultProps = {
    isCourseFavoriting: false,
    afterCourseRemovedFromFavorite: () => null,
    disableHover: false,
    isInDashboard: false,
    className: '',
    searchSessionId: '',
  };

  static contextTypes = {
    fetch: PropTypes.func,
    cloudfrontUrl: PropTypes.string,
    cookiePrefix: PropTypes.string,
    apiUrl: PropTypes.string,
    togglePwa: PropTypes.string,
    organizationSlug: PropTypes.string,
  };

  state = {
    isCourseFavoriting: false,
    isOldBrowserWarning: false,
    isRestrictedAccessModalOpened: false,
  };

  getItemType = () => {
    const {
      item,
      intl: { formatMessage },
    } = this.props;

    const itemTypes = {
      course: formatMessage(messages.courseType),
      livestream: formatMessage(messages.livestreamType),
      podcast: formatMessage(messages.podcastType),
      recording: formatMessage(messages.recordingType),
    };
    if (!item.type || !_.has(itemTypes, item.type)) return itemTypes.course;
    return item.type === 'livestream' && moment().isAfter(item.liveStreamEnd)
      ? itemTypes.recording
      : itemTypes[item.type];
  };

  closeRestrictedAccessModal = () => {
    this.setState({
      isRestrictedAccessModalOpened: false,
    });
  };

  toggleFavorite = _id => {
    const {
      favoriteCourses,
      saveFavoriteCourses,
      afterCourseRemovedFromFavorite,
    } = this.props;

    this.setState({
      isCourseFavoriting: true,
    });

    if (favoriteCourses.includes(_id)) {
      return this.context
        .fetch(`/api/auth/favorite-courses/${_id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': undefined,
            Accept: '*/*',
          },
        })
        .then(() => saveFavoriteCourses(_.without(favoriteCourses, _id)))
        .then(() => {
          this.setState({
            isCourseFavoriting: false,
          });

          afterCourseRemovedFromFavorite(_id);
        })
        .catch(error => {
          console.error(error);
          this.setState({
            isCourseFavoriting: false,
          });
        });
    }

    return this.context
      .fetch(`/api/auth/favorite-courses/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': undefined,
          Accept: '*/*',
        },
      })
      .then(() => saveFavoriteCourses(favoriteCourses.concat([_id])))
      .then(() => {
        this.setState({
          isCourseFavoriting: false,
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isCourseFavoriting: false,
        });
      });
  };

  handleCloseOldBrowserSnackbar = () => {
    this.setState({
      isOldBrowserWarning: false,
    });
  };

  trackGACourseItemClick = () => {
    const { item, auth } = this.props;

    if (window && window !== undefined) {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: 'course_click',
        organization_slug: getWhitelabeledOrganizationSlug(auth) || null,
        organization_id: auth?.user?.mainGroup?._id || null,
        organization_name: auth?.user?.mainGroup?.name || null,
        is_course_public: item?.isPublic || false,
        course_name: item?.name,
        course_id: item?._id,
      });
    }
  };

  render() {
    const {
      item,
      classes,
      favoriteCourses,
      disableHover,
      className,
      auth,
      isInDashboard,
      intl: { formatMessage },
      cookies,
      isCourseDownloading,
      inOneColumn,
      searchSessionId,
      ...rest
    } = this.props;

    const {
      isCourseFavoriting,
      isOldBrowserWarning,
      isRestrictedAccessModalOpened,
    } = this.state;

    const timeMinutes = (item.time || 0) % 60;
    const timeHours = ((item.time || 0) - timeMinutes) / 60;
    const isCourseNew = moment().diff(moment(item.createDate), 'days') <= 31;

    const isItemInFavoriteCourses = (favoriteCourses || []).includes(item._id);

    const getBadge = () => {
      if (isLoggedIn(auth) && item.hasTask) {
        return (
          <div className={classNames(s.courseItemBadge, s.courseItemBadgeTask)}>
            <FormattedMessage {...messages.hasTaskLabel} />
          </div>
        );
      }
      if (item.isFeatured && !item.hasTask && !item.liveStreamStart) {
        return (
          <div
            className={classNames(s.courseItemBadge, s.courseItemBadgeFeatured)}
          >
            <FormattedMessage {...messages.isFeaturedLabel} />
          </div>
        );
      }
      if (item.isStreamingNow) {
        return (
          <div
            className={classNames(s.courseItemBadge, s.livestreamStartBadge)}
          >
            <span className={s.whitePoint} />
            <FormattedMessage {...messages.streamingNowLive} />
          </div>
        );
      }
      if (
        item.type === 'livestream' &&
        !item.isStreamingNow &&
        moment().isBefore(item.liveStreamStart)
      ) {
        return (
          <div
            className={classNames(s.courseItemBadge, s.livestreamStartBadge)}
          >
            <span>{moment(item.liveStreamStart).format('D.M.YYYY HH:mm')}</span>
          </div>
        );
      }
      if (
        isCourseNew &&
        !item.isFeatured &&
        !item.hasTask &&
        !item.liveStreamStart
      ) {
        return (
          <div className={classNames(s.courseItemBadge, s.courseItemBadgeNew)}>
            <FormattedMessage {...messages.isNewLabel} />
          </div>
        );
      }

      return null;
    };
    return (
      <>
        <Tooltip placement="top" title={item.name}>
          <Paper
            aria-label={item.name}
            className={classNames(
              s.courseItem,
              disableHover && s.disableHover,
              className,
              isInDashboard && s.inDashboard,
              !item.isAvailable && s.addonCourse,
              { [s.inOneColumn]: inOneColumn },
            )}
            {..._.omit(rest, [
              'afterCourseRemovedFromFavorite',
              'saveFavoriteCourses',
              'isCourseFavoriting',
              'toggleNewVersionAvailableSnackbar',
              'allCookies',
              'swState',
            ])}
            data-test="course-item"
            data-test-value={item.name}
          >
            <ButtonBase
              onClickCapture={async e => {
                // Have to check if onFavourite button click
                // to prevent click to go Course detail page

                let { type } = e.target.dataset;

                if (!type && e.target.closest('[data-type="favorite"]')) {
                  type = 'favorite';
                }

                if (['favorite'].includes(type)) {
                  e.stopPropagation();
                  e.preventDefault();

                  if (type === 'favorite' && isCourseFavoriting) {
                    return;
                  }

                  if (type === 'favorite' && !isCourseFavoriting) {
                    this.toggleFavorite(item._id);
                  }
                }

                this.trackGACourseItemClick();
              }}
              component={Link}
              to={`/courses/${item.slug}?${buildQuery({ searchSessionId })}`}
              className={classNames(s.courseItemLink, {
                [s.inDashboard]: isInDashboard,
                [s.inOneColumn]: inOneColumn,
              })}
              // disabled={isCourseFavoriting || isSavingForOffline}
              focusRipple
            >
              <div
                className={classNames(s.imgWrapper, {
                  [s.inDashboard]: isInDashboard,
                  [s.inOneColumn]: inOneColumn,
                })}
              >
                {getBadge()}
                <img
                  src={`${this.context.cloudfrontUrl}/${_.get(
                    item,
                    'thumbnail.path',
                    'default.svg',
                  )}`}
                  alt={item.name}
                  className={classNames(s.courseItemImage, {
                    [s.inDashboard]: isInDashboard,
                  })}
                  onError={handleErroredImage}
                />
              </div>
              <div
                className={classNames(s.courseItemTextDataWrapper, {
                  [s.inDashboard]: isInDashboard,
                  [s.inOneColumn]: inOneColumn,
                })}
              >
                <Typography className={s.itemType} component="div">
                  {_.toUpper(this.getItemType())}
                </Typography>
                <Typography
                  variant="h2"
                  className={classNames(s.courseItemTitle, {
                    [s.inDashboard]: isInDashboard,
                    [s.inOneColumn]: inOneColumn,
                  })}
                  data-test="course-name"
                >
                  <Interweave
                    content={item.name}
                    tagName="div"
                    className={classNames(s.courseItemTitleHTMLContent, {
                      [s.inOneLine]: inOneColumn,
                      [s.inTwoLine]: !inOneColumn,
                    })}
                  />
                </Typography>
                {inOneColumn && (
                  <div className={s.description}>
                    <Interweave content={item.shortDescription} noWrap />
                  </div>
                )}
                <div
                  className={classNames(s.courseItemDetails, {
                    [s.inDashboard]: isInDashboard,
                    [s.inOneColumn]: inOneColumn,
                  })}
                >
                  {_.get(item, 'type') !== 'livestream' && (
                    <Typography
                      variant="subtitle1"
                      className={classNames(s.courseItemDetailsStats, {
                        [s.inDashboard]: isInDashboard,
                      })}
                      component="div"
                    >
                      {item.totalLessons}
                      <span>&nbsp;</span>
                      <FormattedMessage {...messages.totalLessonsText} />
                      <span>&nbsp;</span>
                      <span className={s.hideInSmallMobile}>
                        {!!item.time && (
                          <FormattedMessage
                            {...(timeHours > 0
                              ? messages.courseTimeText
                              : messages.courseTimeMinutesText)}
                            values={{ hours: timeHours, minutes: timeMinutes }}
                          />
                        )}
                      </span>
                    </Typography>
                  )}
                  {item.type === 'livestream' && (
                    <Typography
                      variant="subtitle1"
                      className={classNames(s.courseItemDetailsStats, {
                        [s.inDashboard]: isInDashboard,
                      })}
                      component="div"
                    >
                      {isSameDay(item.liveStreamStart, item.liveStreamEnd) &&
                      moment().isAfter(item.liveStreamEnd) ? (
                        formatLivestreamDate(item.liveStreamStart)
                      ) : (
                        <FormattedMessage
                          {...messages.streamingStartAt}
                          values={formatLivestreamDate(
                            item.liveStreamStart,
                            item.liveStreamEnd,
                            moment().isAfter(item.liveStreamEnd),
                          )}
                        />
                      )}
                    </Typography>
                  )}
                  <div
                    className={classNames(s.actions, 'course-item-actions', {
                      [s.livestreamActions]: item.type === 'livestream',
                    })}
                  >
                    {isLoggedIn(auth) && item.isAvailable && (
                      <div className={s.relative} data-type="favorite">
                        <Checkbox
                          className={s.favouriteRoot}
                          // disabled={isCourseFavoriting}
                          checked={isItemInFavoriteCourses}
                          onClick={() => this.toggleFavorite(item._id)}
                          color="primary"
                          icon={<SkholeIcon03 style={{ fontSize: 20 }} />}
                          checkedIcon={
                            <SkholeIcon02 style={{ fontSize: 20 }} />
                          }
                          inputProps={{
                            'data-type': 'favorite',
                            'aria-label': formatMessage(
                              isItemInFavoriteCourses
                                ? messages.removeFromFavourites
                                : messages.addToFavourites,
                            ),
                          }}
                        />
                        {isCourseFavoriting && (
                          <CircularProgressSmall
                            wrapperClassName={s.cacheUpdatingProgress}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ButtonBase>
            {!!item.progress && (
              <LinearProgress
                variant="determinate"
                value={item.progress}
                color="primary"
                classes={{
                  root: classes.courseProgressRoot,
                  barColorSecondary: classes.courseProgressBar,
                }}
              />
            )}
          </Paper>
        </Tooltip>
        <Snackbar
          open={isOldBrowserWarning}
          onClose={this.handleCloseOldBrowserSnackbar}
          autoHideDuration={3000}
          message={formatMessage(messages.browserDoesNotSupportCaching)}
        />
        <Modal
          open={isRestrictedAccessModalOpened}
          onClose={this.closeRestrictedAccessModal}
        >
          <RestrictedAccessModal onClose={this.closeRestrictedAccessModal} />
        </Modal>
      </>
    );
  }
}
