// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-no-translate]{translate:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.cXGQC{background:var(--whitelabeling-main-header-color);color:#fff}@media print{.cXGQC{display:none}}.DwkGw{align-items:center;display:flex;justify-content:space-between;margin:0 auto;padding:12px 37px}@media(max-width: 767px){.DwkGw{max-height:55px;padding:10px 15px}}@media only screen and (max-width: 767px){.DwkGw{padding:10px 5px 10px 10px}}.CPJko{align-items:center;display:flex}@media(max-width: 991px){.CPJko{flex:1 1;justify-content:flex-start}}@media(max-width: 767px){.CPJko{justify-content:space-between}}.u2b_S{color:#61dafb;font-size:1.75em;margin-right:37px;text-decoration:none}@media(max-width: 991px){.u2b_S{margin-right:30px}}@media only screen and (max-width: 767px){.u2b_S{font-size:1em;margin-right:5px}}.jPyqm{display:inline-block;height:29px;margin-right:26px;width:230px}@media(max-width: 991px){.jPyqm{margin-right:16px;width:190px}}@media(max-width: 767px){.jPyqm{display:none}}.j0Dbc{height:29px}.j0Dbc label{top:-16px}.j0Dbc label+.k_hoH{margin-top:0}.k_hoH{height:29px}.ckopK{top:-16px}.nJSgH span svg{height:14px;width:14px}.nJSgH span{margin-left:5px;padding-top:10px}.QojVP{margin-top:5px}.aiEPA{display:none}@media(max-width: 767px){.aiEPA{-webkit-box-shadow:0 13px 12px -4px rgba(0,19,58,.29);box-shadow:0 13px 12px -4px rgba(0,19,58,.29);display:flex;flex:1 1;flex-direction:column;height:107px;justify-content:space-around;padding:0 15px}}.ZEGw9{flex:1 1}.Zz3IC{padding-top:20px}.oFKB3{background-color:var(--whitelabeling-main-progress-bar-color);height:4px;left:0;position:fixed;top:0;z-index:1}.FJheV{background-size:122px 36px;height:36px;width:122px}@media(max-width: 767px){.FJheV{align-self:center;background-size:80px 24px;height:24px;width:80px}}.majQn{background-color:var(--whitelabeling-panel-color);color:#000;padding:15px 37px}.a2Cg1{font-size:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "cXGQC",
	"container": "DwkGw",
	"flex": "CPJko",
	"brand": "u2b_S",
	"searchWrapper": "jPyqm",
	"searchField": "j0Dbc",
	"searchFieldRoot": "k_hoH",
	"searchInputLabelRoot": "ckopK",
	"searchIconButtonRoot": "nJSgH",
	"inputLabelShrink": "QojVP",
	"mobileFlexSearchWrapper": "aiEPA",
	"mobileSearchWrapper": "ZEGw9",
	"autocompleteSearchField": "Zz3IC",
	"loadingBar": "oFKB3",
	"logotype": "FJheV",
	"offlineTitle": "majQn",
	"offline": "a2Cg1"
};
module.exports = ___CSS_LOADER_EXPORT___;
