import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loadingBarReducer } from 'react-redux-loading-bar';

import auth from './auth';
import runtime from './runtime';
import intl from './intl';
import editCourse from './editCourse';
import chooseLesson from './chooseLesson';
import editLesson from './editLesson';
import courses from './courses';
import homeLessons from './homeLessons';
import courseDetails from './courseDetails';
import lessonDetails from './lessonDetails';
import users from './users';
import mainGroups from './mainGroups';
import subgroups from './subgroups';
import editMainGroup from './editMainGroup';
import editSubgroup from './editSubgroup';
import quizzes from './quizzes';
import editQuiz from './editQuiz';
import tags from './tags';
import contentCategories from './contentCategories';
import manageTags from './manageTags';
import editTag from './editTag';
import registration from './registration';
import editUser from './editUser';
import authors from './authors';
import editAuthor from './editAuthor';
import questions from './questions';
import editQuestion from './editQuestion';
import search from './search';
import viewQuiz from './viewQuiz';
import quizResult from './quizResult';
import cert from './cert';
import discussions from './discussions';
import editDiscussion from './editDiscussion';
import discussionMessages from './discussionMessages';
import groupTasks from './groupTasks';
import editTask from './editGroupTask';
import notifications from './notifications';
import myTasks from './myTasks';
import taskResults from './taskResults';
import attemptsResults from './attemptsResults';
import quizAttemptsResults from './quizAttemptsResults';
import quizStatistics from './quizStatistics';
import assignmentStatistics from './assignmentStatistics';
import courseStatistics from './courseStatistics';
import studiedLessons from './studiedLessons';
import quizImprovers from './quizImprovers';
import followedDiscussions from './dashboardDiscussions';
import transcodingJobs from './transcodingJobs';
import usersActivityStatistics from './usersActivityStatistics';
import tours from './tours';
import online from './online';
import pipedriveDeals from './pipedriveDeals';
import pipedriveProducts from './pipedriveProducts';
import xlsxExport from './xlsxExport';
import assignmentTask from './assignmentTask';
import podcastLesson from './podcastLesson';
import courseReview from './courseReview';
import userCourseCompletedLessons from './userCourseCompletedLessons';
import trialMainGroupInfo from './trialMainGroupInfo';
import apiKeys from './apiKeys';
import editApiKey from './editApiKey';
import twoFaSettings from './twoFaSettings';
import documentForms from './documentForms';
import editDocumentForm from './editDocumentForm';
import editDocument from './editDocument';
import userDocument from './userDocument';
import demonstrations from './demonstrations';
import editDemonstration from './editDemonstration';
import demonstrationTask from './demonstrationTask';
import demonstrationStatistics from './demonstrationStatistics';

export default function createRootReducer() {
  return combineReducers({
    auth,
    runtime,
    intl,
    form: formReducer,
    loadingBar: loadingBarReducer,
    editCourse,
    chooseLesson,
    editLesson,
    courses,
    homeLessons,
    courseDetails,
    lessonDetails,
    users,
    mainGroups,
    subgroups,
    editMainGroup,
    editSubgroup,
    quizzes,
    editQuiz,
    tags,
    contentCategories,
    manageTags,
    editTag,
    registration,
    editUser,
    authors,
    editAuthor,
    questions,
    editQuestion,
    search,
    viewQuiz,
    quizResult,
    cert,
    discussions,
    editDiscussion,
    discussionMessages,
    groupTasks,
    editTask,
    notifications,
    myTasks,
    taskResults,
    attemptsResults,
    quizAttemptsResults,
    quizStatistics,
    assignmentStatistics,
    courseStatistics,
    studiedLessons,
    quizImprovers,
    followedDiscussions,
    transcodingJobs,
    usersActivityStatistics,
    tours,
    online,
    pipedriveDeals,
    pipedriveProducts,
    xlsxExport,
    assignmentTask,
    podcastLesson,
    courseReview,
    userCourseCompletedLessons,
    trialMainGroupInfo,
    apiKeys,
    editApiKey,
    twoFaSettings,
    documentForms,
    editDocumentForm,
    editDocument,
    userDocument,
    demonstrations,
    editDemonstration,
    demonstrationTask,
    demonstrationStatistics,
  });
}
