// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-no-translate]{translate:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.GpewE{display:flex;flex-direction:row}@media(max-width: 767px){.GpewE{flex-direction:column}}.ddpcw{width:100%}.MZvjD{height:100%;margin:10px 20px;overflow-x:hidden;overflow-y:auto;position:relative;width:100%}@media(min-width: 768px){.MZvjD{max-height:calc(100vh - 120px);width:auto}}@media only screen and (max-width: 767px){.MZvjD{height:auto;margin:0 0 10px}}.MZvjD .GNe0y{max-width:490px;width:calc(100% - 30px)}@media only screen and (max-width: 767px){.MZvjD .GNe0y{flex-direction:column;max-width:100%;width:100%}}.EvOv5{bottom:0;height:100px;left:0;margin:auto;position:absolute;right:0;top:0;width:100px;z-index:50}.IFFDC{margin:0 0 5px;width:100%}@media only screen and (max-width: 767px){.IFFDC{margin:0 0 10px;width:100%}}.FzueB{height:22px;padding:0;width:32px}@media only screen and (max-width: 767px){.FzueB{height:40px;margin-right:10px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}}.ueUsX{color:var(--whitelabeling-primary-color);height:15px;width:15px}.eJONK{align-items:flex-start;display:flex;margin-left:0;width:100%}@media(max-width: 767px){.eJONK{align-items:center}}.CO_oY{line-height:1.2;margin-bottom:4px}@media(max-width: 767px){.CO_oY{margin-bottom:0}}.a5YZC{margin-bottom:20px;margin-top:10px;width:100%}.hM8uX{right:12px !important;top:7px !important}.hM8uX button{height:24px;min-height:24px;width:24px}.hM8uX svg{height:20px;width:20px}.mq9y5{border-radius:4px;overflow:initial;padding:0}@media(max-width: 767px){.mq9y5{border-radius:0;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;left:0;margin-top:0;max-height:-webkit-fit-content;max-height:-moz-fit-content;max-height:fit-content;max-width:100%;min-height:-webkit-fit-content;min-height:-moz-fit-content;min-height:fit-content;top:0;width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categories": "GpewE",
	"collapseContainer": "ddpcw",
	"popoverContent": "MZvjD",
	"tagsGrid": "GNe0y",
	"loadingWrapper": "EvOv5",
	"checkboxSelectAll": "IFFDC",
	"categoryCheckbox": "FzueB",
	"checkboxIcon": "ueUsX",
	"checkboxFormControl": "eJONK",
	"checkboxFormControlLabel": "CO_oY",
	"applyCategoryFilterBtn": "a5YZC",
	"categoryModalCloseBtn": "hM8uX",
	"tagsIndexPopover": "mq9y5"
};
module.exports = ___CSS_LOADER_EXPORT___;
