import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Cookies, withCookies } from 'react-cookie';
import isMobileUserAgent from '../helpers/checkMobileUserAgent';
import isLoggedIn from '../helpers/checkAuth';
import Intercom from '../widgets/Intercom';

export default function(WrappedComponent) {
  @withCookies
  @connect(state => ({
    auth: state.auth,
    lang: state.intl.locale,
    turnedOffPwaOffline: state.online.turnedOffPwaOffline,
  }))
  class WithIntercom extends React.Component {
    static propTypes = {
      auth: PropTypes.shape({
        user: PropTypes.shape({}),
      }),
      disableAutoBoot: PropTypes.bool,
      lang: PropTypes.string.isRequired,
      turnedOffPwaOffline: PropTypes.bool.isRequired,
      cookies: PropTypes.instanceOf(Cookies).isRequired,
    };

    static defaultProps = {
      auth: PropTypes.shape({}),
      disableAutoBoot: false,
    };

    static contextTypes = {
      whitelabeling: PropTypes.shape({}),
    };

    constructor(props) {
      super(props);
      this.handleOnScrollWithDebounce = _.debounce(this.handleOnScroll, 300);
    }

    componentDidMount() {
      this.isMobile = isMobileUserAgent();
      window.addEventListener('scroll', this.handleOnScrollWithDebounce);

      if (this.props.disableAutoBoot || this.props.turnedOffPwaOffline) {
        return;
      }

      Intercom.boot(this.props.auth.user, this.context.whitelabeling);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.lang && this.props.lang !== nextProps.lang) {
        Intercom.setNewLang(nextProps.lang.slice(0, 2));
      }

      if (isLoggedIn(this.props.auth) && !isLoggedIn(nextProps.auth)) {
        Intercom.logout();
      }

      if (!this.props.turnedOffPwaOffline && nextProps.turnedOffPwaOffline) {
        Intercom.hideWidget();
      }

      if (
        this.props.turnedOffPwaOffline &&
        !nextProps.turnedOffPwaOffline &&
        !nextProps.disableAutoBoot
      ) {
        Intercom.boot(nextProps.auth.user, this.context.whitelabeling);
      }

      if (typeof window !== 'undefined') {
        const currentURL = window.location.pathname;
        Intercom.changeWidgetPosition(currentURL);
      }
    }

    componentWillUnmount() {
      Intercom.hideWidget();
      window.removeEventListener('scroll', this.handleOnScrollWithDebounce);
    }

    handleOnScroll = () => {
      const isScrolled =
        !!(document.documentElement && document.documentElement.scrollTop) ||
        !!document.body.scrollTop;

      if (this.isMobile && isScrolled) {
        Intercom.hideWidget();
      } else if (
        this.isMobile &&
        !isScrolled &&
        !this.props.turnedOffPwaOffline
      ) {
        Intercom.rebootMobile();
      }
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithIntercom;
}
