// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-no-translate]{translate:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.LMu0J{position:absolute;top:100px;z-index:10}@media(max-width: 991px){.LMu0J{display:none}}.LMu0J>div{flex-wrap:nowrap}.UNmEN{min-width:48px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LMu0J",
	"actions": "UNmEN"
};
module.exports = ___CSS_LOADER_EXPORT___;
