import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ButtonBase from '@material-ui/core/ButtonBase';
import LinearProgress from '@material-ui/core/LinearProgress';

import { bindActionCreators } from 'redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';

import { Interweave } from 'interweave';
import * as notificationsActions from '../../../actions/notifications';

import Link from '../../Link';
import CustomModal from '../../Modals/Modal';
import {
  getSystemMessageBody,
  getSystemMessageTitle,
  getWhitelabeledOrganizationName,
} from '../../../helpers';
import { ModalCloseBtn } from '../../statelessComponents/Buttons/ButtonsInModals';
import messages from './messages';

import s from './Notifications.scss';

@injectIntl
@withStyles(s)
@connect(
  state => ({
    auth: state.auth,
    notifications: state.notifications.notifications,
    total: state.notifications.total,
    loading: state.notifications.loading,
    locale: state.intl.locale,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...notificationsActions,
      },
      dispatch,
    ),
)
export default class Notifications extends React.Component {
  static propTypes = {
    auth: PropTypes.object, // eslint-disable-line
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        task: PropTypes.shape({
          _id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        }),
      }),
    ).isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    readNotification: PropTypes.func.isRequired,
    readAllNotifications: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    setFirstPagePagination: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static contextTypes = {
    fetch: PropTypes.func,
    apiUrl: PropTypes.string,
    organizationSlug: PropTypes.string,
  };

  state = {
    page: 1,
    perPage: 10,
    systemMessage: null,
  };

  componentDidMount() {
    const { perPage } = this.state;
    const { loading, setFirstPagePagination } = this.props;
    if (!loading) {
      setFirstPagePagination(perPage);
    }
  }

  onNotificationClick = notificationId =>
    this.context
      .fetch(`/api/notifications/${notificationId}/read`, {
        method: 'PUT',
      })
      .then(() => {
        this.props.readNotification(notificationId);
      })
      .catch(console.error());

  loadMore = () => {
    const { loading } = this.props;
    const { page } = this.state;
    if (loading) return;
    this.setState({ page: page + 1 }, this.loadNotifications);
  };

  loadNotifications = () => {
    const { getNotifications } = this.props;
    const { perPage, page } = this.state;
    const params = {
      page,
      perPage,
    };

    getNotifications(params);
  };

  openSystemMessage = systemMessageId => {
    this.context
      .fetch(`/api/system_messages/${systemMessageId}`, {
        method: 'GET',
      })
      .then(async res => {
        const systemMessage = await res.json();
        if (res.ok) {
          this.setState({
            systemMessage,
          });
        }
      })
      .catch(console.error);
  };

  closeSystemMessage = () => {
    this.setState({
      systemMessage: null,
    });
  };

  readAllNotifications = () =>
    this.context
      .fetch(`/api/notifications/read`, {
        method: 'PUT',
      })
      .then(() => {
        this.props.readAllNotifications();
        this.setState({ page: 1 });
      })
      .catch(console.error());

  renderAssignmentsNotification = ({
    item,
    onClick,
    primary,
    secondary,
    to,
  }) => (
    <div key={item._id}>
      <ListItem
        className={classNames(s.listItem, {
          [s.unreadNotification]: !item.readDate,
        })}
        component={to ? Link : ButtonBase}
        to={to}
        onClick={onClick}
        button
        dense
        divider
      >
        <ListItemText
          primary={primary}
          secondary={secondary}
          classes={{
            primary: s.primaryListItemText,
            secondary: s.secondaryListItemText,
          }}
        />
      </ListItem>
    </div>
  );

  renderAssignmentChangesAreRequested = item => {
    const { onClose, locale } = this.props;
    const onClick = () => {
      onClose();
      return !item.readDate && this.onNotificationClick(item._id);
    };
    const primary = (
      <div>
        <FormattedHTMLMessage
          {...(item.type === 'demonstration_changes_are_requested'
            ? messages.demonstrationChangesAreRequested
            : messages.changesAreRequested)}
          values={{
            taskName: _.get(item, 'task.name', ''),
            firstName: _.get(item, 'task.createdByUser.firstName'),
            lastName: _.get(item, 'task.createdByUser.lastName'),
          }}
        />
      </div>
    );
    const secondary = (
      <div>
        <b>
          {moment(_.get(item, 'createDate'))
            .locale(locale.split('-')[0])
            .format('D.M.YYYY HH:mm')}
        </b>
      </div>
    );
    const to = `/tasks?ts=${_.get(item, 'createDate')}`;
    return this.renderAssignmentsNotification({
      item,
      onClick,
      primary,
      secondary,
      to,
    });
  };

  renderAssignmentIsCompletedByStudent = item => {
    const { locale, onClose } = this.props;
    const onClick = () => {
      onClose();
      return !item.readDate && this.onNotificationClick(item._id);
    };
    const primary = (
      <div>
        <FormattedHTMLMessage
          {...messages.assignmentIsCompletedByStudent}
          values={{
            taskName: _.get(item, 'task.name'),
            firstName: _.get(item, 'task.createdByUser.firstName'),
            lastName: _.get(item, 'task.createdByUser.lastName'),
            score: _.get(item, 'userAssignmentResultId.score'),
            maxPoints: _.get(item, 'task.assignmentMaxScore'),
          }}
        />
      </div>
    );
    const secondary = (
      <div>
        <b>
          {moment(_.get(item, 'createDate'))
            .locale(locale.split('-')[0])
            .format('D.M.YYYY HH:mm')}
        </b>
      </div>
    );
    const to = `/tasks?isCompleted=true&ts=${_.get(item, 'createDate')}`;
    return this.renderAssignmentsNotification({
      item,
      onClick,
      primary,
      secondary,
      to,
    });
  };

  renderDemonstrationIsCompletedByStudent = item => {
    const { locale, onClose } = this.props;
    const onClick = () => {
      onClose();
      return !item.readDate && this.onNotificationClick(item._id);
    };
    const primary = (
      <div>
        <FormattedHTMLMessage
          {...messages.demonstrationIsCompletedByStudent}
          values={{
            taskName: _.get(item, 'task.name'),
            firstName: _.get(item, 'task.createdByUser.firstName'),
            lastName: _.get(item, 'task.createdByUser.lastName'),
          }}
        />
      </div>
    );
    const secondary = (
      <div>
        <b>
          {moment(_.get(item, 'createDate'))
            .locale(locale.split('-')[0])
            .format('D.M.YYYY HH:mm')}
        </b>
      </div>
    );
    const to = `/tasks?isCompleted=true&ts=${_.get(item, 'createDate')}`;
    return this.renderAssignmentsNotification({
      item,
      onClick,
      primary,
      secondary,
      to,
    });
  };

  renderAssignmentsSentByStudent = item => {
    const {
      locale,
      onClose,
      intl: { formatMessage },
    } = this.props;
    const onClick = () => {
      onClose();
      return !item.readDate && this.onNotificationClick(item._id);
    };
    const primary = (
      <div>
        <FormattedHTMLMessage
          {...messages.assignmentIsSentByStudent}
          values={{
            firstName: _.get(item, 'ownerIds.0.firstName', ''),
            lastName: _.get(item, 'ownerIds.0.lastName', ''),
            taskName: _.get(item, 'task.name'),
            deliveryType: _.get(
              messages,
              `assignmentType_${_.get(item, 'task.assignmentDeliverableType')}`,
            )
              ? formatMessage(
                  messages[
                    `assignmentType_${item.task.assignmentDeliverableType}`
                  ],
                )
              : '',
          }}
        />
      </div>
    );
    const secondary = (
      <div>
        <b>
          {moment(_.get(item, 'createDate'))
            .locale(locale.split('-')[0])
            .format('D.M.YYYY HH:mm')}
        </b>
      </div>
    );
    const to = `/group-tasks?ts=${_.get(item, 'createDate')}`;
    return this.renderAssignmentsNotification({
      item,
      onClick,
      primary,
      secondary,
      to,
    });
  };

  renderReminderNotification = item => {
    const { onClose, locale } = this.props;

    const onClick = () => {
      onClose();
      return !item.readDate && this.onNotificationClick(item._id);
    };

    const primary = (
      <div>
        <FormattedHTMLMessage
          {...messages.taskReminder}
          values={{
            taskName: _.get(item, 'task.name'),
            endTime: moment(item.task.endDate)
              .locale(locale.split('-')[0])
              .format('D.M.YYYY HH:mm'),
          }}
        />
      </div>
    );
    const secondary = (
      <div>
        <FormattedHTMLMessage
          {...messages.completeTime}
          values={{
            from: moment(item.task.startDate)
              .locale(locale.split('-')[0])
              .format('D.M.YYYY HH:mm'),
            to: moment(item.task.endDate)
              .locale(locale.split('-')[0])
              .format('D.M.YYYY HH:mm'),
          }}
        />
      </div>
    );
    const to = `/tasks`;
    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          component={to ? Link : ButtonBase}
          to={to}
          onClick={onClick}
          button
          dense
          divider
        >
          <ListItemText
            primary={primary}
            secondary={secondary}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  renderTaskNotification = (item, prolonged = false) => {
    const { locale, onClose } = this.props;

    let secondary = '';
    let primary = '';
    let to;
    const onClick = () => {
      onClose();
      return !item.readDate && this.onNotificationClick(item._id);
    };

    if (_.get(item, 'task.type') === 'course') {
      primary = (
        <div>
          {prolonged && (
            <>
              <b>
                <FormattedMessage {...messages.taskProlonged} />
              </b>
              <br />
            </>
          )}
          <FormattedHTMLMessage
            {...messages.youHaveBeenAssignedToCourse}
            values={{
              name: _.get(item, 'task.courseId.name'),
            }}
          />
        </div>
      );
      secondary = (
        <div>
          <FormattedHTMLMessage
            {...messages.completeTime}
            values={{
              from: moment(item.task.startDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
              to: moment(item.task.endDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
            }}
          />
        </div>
      );
      to = `/courses/${_.get(item, 'task.courseId.slug')}`;
    } else if (_.get(item, 'task.type') === 'exam') {
      primary = (
        <div>
          {prolonged && (
            <>
              <b>
                <FormattedMessage {...messages.taskProlonged} />
              </b>
              <br />
            </>
          )}
          <FormattedHTMLMessage
            {...messages.youHaveBeenAssignedToExamTask}
            values={{
              name: _.get(item, 'task.name'),
            }}
          />
        </div>
      );
      secondary = (
        <div>
          <FormattedHTMLMessage
            {...messages.completeTime}
            values={{
              from: moment(item.task.startDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
              to: moment(item.task.endDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
            }}
          />
        </div>
      );
      to = `/tasks/${_.get(item, 'task._id')}/exams/${_.get(
        item,
        'task.quizId._id',
      )}?exam`;
    } else if (_.get(item, 'task.type') === 'assignment') {
      primary = (
        <div>
          {prolonged && (
            <>
              <b>
                <FormattedMessage {...messages.taskProlonged} />
              </b>
              <br />
            </>
          )}
          <FormattedHTMLMessage
            {...messages.youHaveBeenAssignedToOpenAssignment}
            values={{
              name: _.get(item, 'task.name'),
            }}
          />
        </div>
      );
      secondary = (
        <div>
          <FormattedHTMLMessage
            {...messages.completeTime}
            values={{
              from: moment(item.task.startDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
              to: moment(item.task.endDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
            }}
          />
        </div>
      );
      to = `/tasks?ts=${_.get(item, 'createDate')}`;
    } else if (_.get(item, 'task.type') === 'demonstration') {
      primary = (
        <div>
          {prolonged && (
            <>
              <b>
                <FormattedMessage {...messages.taskProlonged} />
              </b>
              <br />
            </>
          )}
          <FormattedHTMLMessage
            {...messages.youHaveBeenAssignedToDemonstrationTask}
            values={{
              name: _.get(item, 'task.name'),
            }}
          />
        </div>
      );
      secondary = (
        <div>
          <FormattedHTMLMessage
            {...messages.completeTime}
            values={{
              from: moment(item.task.startDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
              to: moment(item.task.endDate)
                .locale(locale.split('-')[0])
                .format('D.M.YYYY HH:mm'),
            }}
          />
        </div>
      );
      to = `/tasks?ts=${_.get(item, 'createDate')}`;
    }

    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          component={to ? Link : ButtonBase}
          to={to}
          onClick={onClick}
          button
          dense
          divider
        >
          <ListItemText
            primary={primary}
            secondary={secondary}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  renderAllCompletedTaskNotification = item => {
    let secondary = '';
    let to;

    if (_.get(item, 'task.type') === 'course') {
      secondary = _.get(item, 'task.courseId.name');
      to = `/group-tasks`;
    } else if (_.get(item, 'task.type') === 'exam') {
      secondary = _.get(item, 'task.quizId.name');
      to = `/group-tasks`;
    }

    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          component={to ? Link : ButtonBase}
          to={to}
          onClick={() => !item.readDate && this.onNotificationClick(item._id)}
          button
          dense
          divider
        >
          <ListItemText
            primary={
              <div>
                <FormattedMessage
                  {...messages.allUsersCompleted}
                  values={{
                    taskName: <strong>{_.get(item, 'task.name')}</strong>,
                  }}
                />
              </div>
            }
            secondary={secondary}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  renderPublicNotesNotification = item => (
    <div key={item._id}>
      <ListItem
        className={classNames(s.listItem, {
          [s.unreadNotification]: !item.readDate,
        })}
        component={Link}
        to={`/profiles/${_.get(item, 'ownerIds.0._id')}`}
        onClick={() => !item.readDate && this.onNotificationClick(item._id)}
        button
        dense
        divider
      >
        <ListItemText
          primary={
            <div>
              <strong>
                {_.get(item, 'ownerIds.0.lastName')}{' '}
                {_.get(item, 'ownerIds.0.firstName')}
              </strong>
              <br />
              <FormattedMessage
                {...messages.sharedNotes}
                values={{
                  courseName: <strong>{_.get(item, 'courses.0.name')}</strong>,
                }}
              />
            </div>
          }
          classes={{
            primary: s.primaryListItemText,
            secondary: s.secondaryListItemText,
          }}
        />
      </ListItem>
    </div>
  );

  renderFollowedDiscussionNotification = item => (
    <div key={item._id}>
      <ListItem
        className={classNames(s.listItem, {
          [s.unreadNotification]: !item.readDate,
        })}
        component={Link}
        to={
          _.get(item, 'discussionId.courseId.slug')
            ? `/${this.props.locale}/courses/${_.get(
                item,
                'discussionId.courseId.slug',
              )}/discussions/${_.get(item, 'discussionId._id')}?ts=${_.get(
                item,
                'createDate',
              )}`
            : `/${this.props.locale}/classroom/followed-discussions/${_.get(
                item,
                'discussionId._id',
              )}?ts=${_.get(item, 'createDate')}`
        }
        onClick={() => !item.readDate && this.onNotificationClick(item._id)}
        button
        dense
        divider
      >
        <ListItemText
          primary={
            <div>
              <strong>
                {_.get(item, 'ownerIds.0.lastName')}{' '}
                {_.get(item, 'ownerIds.0.firstName')}
              </strong>
              <br />
              <FormattedMessage {...messages.followedDiscussion} />
            </div>
          }
          classes={{
            primary: s.primaryListItemText,
            secondary: s.secondaryListItemText,
          }}
        />
      </ListItem>
    </div>
  );

  renderXlsxExportNotification = (item, index) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { organizationSlug } = this.context;
    const type =
      _.get(item, 'xlsxExportId.entity') &&
      messages[_.get(item, 'xlsxExportId.entity')] &&
      formatMessage(messages[_.get(item, 'xlsxExportId.entity')]);

    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          component="a"
          href={`/${this.props.locale}${
            organizationSlug ? `/${organizationSlug}` : ''
          }/xlsx-export/${_.get(item, 'xlsxExportId._id')}`}
          target="_blank"
          onClick={() => !item.readDate && this.onNotificationClick(item._id)}
          action={actions =>
            !index && !item.readDate && _.invoke(actions, 'focusVisible')
          }
          focusRipple={!item.readDate}
          centerRipple={!item.readDate}
          button
          dense
          divider
        >
          <ListItemText
            primary={
              <div>
                <FormattedHTMLMessage
                  {...messages.hereIsXlsxYouExported}
                  values={{
                    type,
                    size: filesize(_.get(item, 'xlsxExportId.file.size', 0)),
                  }}
                />
              </div>
            }
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  renderQuizIsArchivedNotification = (item, index) => {
    const { organizationSlug } = this.context;
    const firstName = _.get(item, 'ownerIds.0.firstName', '');
    const lastName = _.get(item, 'ownerIds.0.lastName', '');
    const quizName = _.get(item, 'quizId.name');
    const courseName = _.get(item, 'courseId.name');
    const courseSlug = _.get(item, 'courseId.slug');
    const courseLang = _.get(item, 'courseId.lang');

    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          component="a"
          href={`/${courseLang}${
            organizationSlug ? `/${organizationSlug}` : ''
          }/courses/${courseSlug}`}
          onClick={() => !item.readDate && this.onNotificationClick(item._id)}
          action={actions =>
            !index && !item.readDate && _.invoke(actions, 'focusVisible')
          }
          focusRipple={!item.readDate}
          centerRipple={!item.readDate}
          button
          dense
          divider
        >
          <ListItemText
            primary={
              <div>
                <FormattedHTMLMessage
                  {...messages.quizIsArchived}
                  values={{
                    firstName,
                    lastName,
                    quizName,
                    courseName,
                    courseSlug,
                    courseLang,
                  }}
                />
              </div>
            }
            secondary={
              <div>
                <FormattedHTMLMessage
                  {...messages.quizIsArchivedSecondary}
                  values={{
                    quizName,
                  }}
                />
              </div>
            }
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  renderLessonMediasAreReady = (item, index) => {
    const { organizationSlug } = this.context;
    const lessonName = _.get(item, 'lessonId.name');
    const lessonSlug = _.get(item, 'lessonId.slug');
    const lessonLang = _.get(item, 'lessonId.lang');

    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          component="a"
          href={`/${lessonLang}${
            organizationSlug ? `/${organizationSlug}` : ''
          }/lessons/${lessonSlug}`}
          onClick={() => !item.readDate && this.onNotificationClick(item._id)}
          action={actions =>
            !index && !item.readDate && _.invoke(actions, 'focusVisible')
          }
          focusRipple={!item.readDate}
          centerRipple={!item.readDate}
          button
          dense
          divider
        >
          <ListItemText
            primary={
              <div>
                <FormattedHTMLMessage
                  {...messages.lessonMediaIsReadyTitle}
                  values={{
                    lessonName,
                  }}
                />
              </div>
            }
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  renderSystemMessage = item => {
    const { auth, locale } = this.props;

    const text = getSystemMessageTitle(item.systemMessageId, locale);
    const onClick = () => {
      this.openSystemMessage(_.get(item, 'systemMessageId._id'));
      if (!item.readDate) {
        this.onNotificationClick(item._id);
      }
    };

    const primary = (
      <div>
        <FormattedHTMLMessage
          {...messages.youGotSystemMessage}
          values={{
            organizationName: getWhitelabeledOrganizationName(auth) || 'Skhole',
          }}
        />
      </div>
    );
    const secondary = (
      <Interweave tagName="div" className={s.truncate} content={text} />
    );

    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          component={ButtonBase}
          onClick={onClick}
          button
          dense
          divider
        >
          <ListItemText
            primary={primary}
            secondary={secondary}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  renderCanceledB2cSubscription = item => {
    // todo refactor it so that each type would have own component
    const {
      locale,
      intl: { formatMessage },
    } = this.props;
    const { whitelabeling } = this.context;
    const serviceName = _.get(
      whitelabeling,
      `serviceName.${locale}`,
      formatMessage(messages.serviceName),
    );

    const onClick = () => !item.readDate && this.onNotificationClick(item._id);

    const primary = (
      <div>
        <FormattedMessage
          {...messages.yourSubscriptionIsExpiring}
          values={{
            serviceName,
          }}
        />
      </div>
    );
    const secondary = formatMessage(messages.yourSubscriptionIsExpiringBody, {
      expiryDateWithNoTime: moment(item.cancelingSubscriptionDate)
        .endOf('day')
        .format('D.M.YYYY'),
    });

    return (
      <div key={item._id}>
        <ListItem
          className={classNames(s.listItem, {
            [s.unreadNotification]: !item.readDate,
          })}
          onClick={onClick}
          button
          dense
          divider
        >
          <ListItemText
            primary={primary}
            secondary={secondary}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>
      </div>
    );
  };

  render() {
    const {
      notifications,
      total,
      loading,
      locale,
      intl: { formatMessage },
    } = this.props;
    const { systemMessage, page } = this.state;

    let notificationToShow = notifications;
    const showOnlyUnread = page === 1;

    if (showOnlyUnread) {
      notificationToShow = _.filter(notifications, ({ readDate }) => !readDate);
    }

    const items = notificationToShow.map((item, index) => {
      if (item.type === 'task') {
        return this.renderTaskNotification(item);
      }

      if (item.type === 'task_prolonged') {
        return this.renderTaskNotification(item, true);
      }

      if (item.type === 'reminder') {
        return this.renderReminderNotification(item);
      }

      if (item.type === 'public_notes') {
        return this.renderPublicNotesNotification(item);
      }

      if (item.type === 'followed_discussion') {
        return this.renderFollowedDiscussionNotification(item);
      }

      if (item.type === 'all_completed_task') {
        return this.renderAllCompletedTaskNotification(item);
      }

      if (item.type === 'xlsx_export') {
        return this.renderXlsxExportNotification(item, index);
      }

      if (item.type === 'quiz_is_archived') {
        return this.renderQuizIsArchivedNotification(item, index);
      }

      if (item.type === 'lesson_media_is_ready') {
        return this.renderLessonMediasAreReady(item, index);
      }

      if (
        [
          'assignment_is_sent_by_student',
          'demonstration_is_sent_by_student',
        ].includes(item.type)
      ) {
        return this.renderAssignmentsSentByStudent(item);
      }

      if (item.type === 'assignment_is_completed') {
        return this.renderAssignmentIsCompletedByStudent(item);
      }

      if (item.type === 'demonstration_is_completed') {
        return this.renderDemonstrationIsCompletedByStudent(item);
      }

      if (
        [
          'assignment_changes_are_requested',
          'demonstration_changes_are_requested',
        ].includes(item.type)
      ) {
        return this.renderAssignmentChangesAreRequested(item);
      }

      if (item.type === 'system_message') {
        return this.renderSystemMessage(item);
      }

      if (item.type === 'canceled_b2c_subscription') {
        return this.renderCanceledB2cSubscription(item);
      }

      return null;
    });

    if (!notifications.length) {
      items.unshift(
        <ListItem key="empty" dense className={s.listItem}>
          <ListItemText
            secondary={formatMessage(messages.noNotificationsYet)}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>,
      );
    }

    if (_.find(notifications, ({ readDate }) => !readDate)) {
      items.unshift(
        <ListItem
          key="mark_as_read"
          onClick={() => this.readAllNotifications()}
          disabled={loading}
          className={s.listItem}
          button
          dense
          divider
        >
          <ListItemText
            primary={formatMessage(messages.markAllAsRead)}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>,
      );
    } else if (showOnlyUnread && notifications.length) {
      items.unshift(
        <ListItem key="empty" dense className={s.listItem}>
          <ListItemText
            secondary={formatMessage(messages.noNewNotificationsYet)}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>,
      );
    }

    if (
      (notifications.length && notifications.length !== total) ||
      notifications.length !== notificationToShow.length
    ) {
      items.push(
        <ListItem
          key="load_more"
          onClick={this.loadMore}
          disabled={loading}
          className={s.listItem}
          button
          divider
          dense
        >
          <ListItemText
            primary={formatMessage(messages.showOlder)}
            classes={{
              primary: s.primaryListItemText,
              secondary: s.secondaryListItemText,
            }}
          />
        </ListItem>,
      );
    }

    if (loading) {
      items.push(<LinearProgress key="progress" className={s.progress} />);
    }

    return (
      <>
        <List component="nav">{items}</List>
        {systemMessage && (
          <CustomModal
            isOpen={!!systemMessage}
            onClose={this.closeSystemMessage}
            width="40%"
          >
            <div className={s.modalWrapper}>
              <Typography variant="h6" component="h2">
                {getSystemMessageTitle(systemMessage, locale)}
              </Typography>
              <Interweave
                tagName="div"
                content={`${getSystemMessageBody(systemMessage, locale)}`}
              />
              <ModalCloseBtn onClick={this.closeSystemMessage} />
            </div>
          </CustomModal>
        )}
      </>
    );
  }
}
