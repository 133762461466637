import {
  GET_DEMONSTRATION,
  GET_DEMONSTRATION_SUCCESS,
  GET_DEMONSTRATION_FAIL,
  RESET_DEMONSTRATION,
  SET_DEMONSTRATION,
} from '../constants';

const initialState = {
  loading: false,
  demonstration: {
    name: '',
    guide: '',
    numberOfActs: 5,
    certificateText: '',
    allowViewOrCopy: false,
    mainGroupId: '',
    contentLang: null,
  },
  error: '',
};

export default function editDemonstration(state = initialState, action) {
  switch (action.type) {
    case GET_DEMONSTRATION:
      return {
        ...state,
        loading: true,
      };
    case GET_DEMONSTRATION_SUCCESS: {
      const demonstration = action.result.json;

      return {
        ...state,
        demonstration,
        loading: false,
      };
    }
    case GET_DEMONSTRATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_DEMONSTRATION:
      return {
        ...state,
        demonstration: action.document,
      };
    case RESET_DEMONSTRATION: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
