// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LezgV{display:none}[data-no-translate]{translate:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}@media only screen and (max-width: 767px){.ic6O6{margin:0 auto;max-height:100%;width:100%}}@media only screen and (max-width: 767px)and (orientation: landscape){.ic6O6{height:100vh;max-height:initial;overflow-x:auto}}@media only screen and (max-width: 767px){.mhUf5>h2{line-height:1.1em}}.geAU5{bottom:0;left:0;margin:auto;position:absolute;right:0;top:0}.xULD8{position:relative}.NyIR3{color:#999}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "LezgV",
	"dialog": "ic6O6",
	"dialogTitle": "mhUf5",
	"buttonProgress": "geAU5",
	"selectFile": "xULD8",
	"editorButton": "NyIR3"
};
module.exports = ___CSS_LOADER_EXPORT___;
