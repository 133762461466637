import {
  GET_DOCUMENT_FORMS,
  GET_DOCUMENT_FORMS_SUCCESS,
  GET_DOCUMENT_FORMS_FAIL,
  RESET_DOCUMENT_FORMS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  documentForms: [],
  total: 0,
  error: '',
};

export default function subgroups(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT_FORMS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_DOCUMENT_FORMS_SUCCESS:
      return {
        ...state,
        documentForms: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_DOCUMENT_FORMS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true,
      };
    case RESET_DOCUMENT_FORMS:
      return initialState;
    default:
      return state;
  }
}
