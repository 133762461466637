import {
  RESET_DEMONSTRATION_TASK,
  DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT,
  DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_SUCCESS,
  DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_FAIL,
  DEMONSTRATION_TASK__GET_TASK_HISTORY,
  DEMONSTRATION_TASK__GET_TASK_HISTORY_SUCCESS,
  DEMONSTRATION_TASK__GET_TASK_HISTORY_FAIL,
  DEMONSTRATION_TASK__GET_USER_TASK_RESULT,
  DEMONSTRATION_TASK__GET_USER_TASK_RESULT_SUCCESS,
  DEMONSTRATION_TASK__GET_USER_TASK_RESULT_FAIL,
} from '../constants';

const initialState = {
  loadingCurrentUserTaskResult: false,
  loadingUserTaskResult: false,
  loadingTaskHistory: false,
  error: null,
  taskResult: {},
  taskHistory: [],
};

export default function demonstrationTask(state = initialState, action) {
  switch (action.type) {
    case DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT:
      return {
        ...state,
        loadingCurrentUserTaskResult: true,
      };
    case DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_SUCCESS: {
      const taskResult = action.result.json;
      return {
        ...state,
        taskResult,
        loadingCurrentUserTaskResult: false,
      };
    }
    case DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_FAIL:
      return {
        ...state,
        loadingCurrentUserTaskResult: false,
        error: action.error,
      };
    case DEMONSTRATION_TASK__GET_USER_TASK_RESULT:
      return {
        ...state,
        loadingUserTaskResult: true,
      };
    case DEMONSTRATION_TASK__GET_USER_TASK_RESULT_SUCCESS: {
      const taskResult = action.result.json;
      return {
        ...state,
        taskResult,
        loadingUserTaskResult: false,
      };
    }
    case DEMONSTRATION_TASK__GET_USER_TASK_RESULT_FAIL:
      return {
        ...state,
        loadingUserTaskResult: false,
        error: action.error,
      };
    case DEMONSTRATION_TASK__GET_TASK_HISTORY:
      return {
        ...state,
        loadingTaskHistory: true,
      };
    case DEMONSTRATION_TASK__GET_TASK_HISTORY_SUCCESS: {
      const taskHistory = action.result.json;
      return {
        ...state,
        taskHistory,
        loadingTaskHistory: false,
      };
    }
    case DEMONSTRATION_TASK__GET_TASK_HISTORY_FAIL:
      return {
        ...state,
        loadingTaskHistory: false,
        error: action.error,
      };
    case RESET_DEMONSTRATION_TASK:
      return initialState;
    default:
      return state;
  }
}
