import getVideoId from 'get-video-id';
import _ from 'lodash';
import { convertFromRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import queryString from 'query-string';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { isDirty, isPristine } from 'redux-form';
import { createEditorState } from 'medium-draft';
import { extendMoment } from 'moment-range';
import slugify from 'slugify';
import notFound from '../routes/not-found';
import notAuthorized from '../routes/not-authorized';
import {
  emptyContent,
  emptyContentState,
} from '../components/RichEditor/RichEditor';
import entityStyleFn from './entityStyleFn';
import { userRole } from '../constants';
import isLoggedIn from './checkAuth';

const moment = extendMoment(require('moment'));

export const buildQuery = (
  // eslint-disable-line
  params,
) =>
  queryString.stringify(
    _.pickBy(
      params,
      value =>
        (!_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)) &&
        value !== undefined,
    ),
    { arrayFormat: 'bracket' },
  );

export const isTeacher = user =>
  user &&
  user.user &&
  [
    userRole.TEACHER,
    userRole.GROUP_LEADER,
    userRole.GROUP_MANAGER,
    userRole.ADMIN,
  ].includes(user.user.role);

export const isEditor = user =>
  user &&
  user.user &&
  (user.user.role === userRole.ADMIN || user.user.isEditor);

export const isGroupLeader = user =>
  user &&
  user.user &&
  [userRole.GROUP_LEADER, userRole.GROUP_MANAGER, userRole.ADMIN].includes(
    user.user.role,
  );

export const isGroupManagerOnly = user =>
  user && user.user && user.user.role === userRole.GROUP_MANAGER;

export const isGroupAdmin = user =>
  user &&
  user.user &&
  [userRole.ADMIN, userRole.GROUP_LEADER].includes(user.user.role);

export const isAdmin = user =>
  user && user.user && [userRole.ADMIN].includes(user.user.role);

export const isUserFromWhitelabeledOrganization = user =>
  _.get(user, 'user.mainGroup.isWhitelabeled') ||
  _.get(user, 'user.mainGroup.mainGroup.isWhitelabeled');

export const getWhitelabeledOrganizationSlug = user =>
  _.get(user, 'user.mainGroup.slug') ||
  _.get(user, 'user.mainGroup.mainGroup.slug');

export const getWhitelabeledOrganizationName = user =>
  _.get(user, 'user.mainGroup.name') ||
  _.get(user, 'user.mainGroup.mainGroup.name');

export const getWhitelabeledOrganizationId = group =>
  _.get(group, 'mainGroup._id') || _.get(group, '_id');

export const isWhitelabeledOrganization = mainGroup =>
  _.get(mainGroup, 'isWhitelabeled') ||
  _.get(mainGroup, 'mainGroup.isWhitelabeled');

export const isWhitelabeledOrganizationAdmin = user =>
  user &&
  user.user &&
  (user.user.role === userRole.GROUP_LEADER ||
    user.user.role === userRole.GROUP_MANAGER) &&
  user.user.mainGroup.isWhitelabeled;

export const isOwner = (user, createdBy, includeAdmin = true) =>
  user &&
  user.user &&
  (user.user._id === createdBy ||
    (includeAdmin && user.user.role === userRole.ADMIN));

export const isMainGroupMember = (user, mainGroupId) =>
  user &&
  user.user &&
  (user.user.mainGroupId === mainGroupId || user.user.role === userRole.ADMIN);

export const isProfessionalMainGroup = user =>
  _.get(user, 'user.mainGroup.type') === 'professional';

export const isTrial = user => _.get(user, 'user.mainGroup.trial', false);

export const isFormHasUnsavedChanges = ({
  form = null,
  formName,
  allForms = null,
}) => {
  const currentForm = form || allForms[formName];
  if (!currentForm) return false;
  if (currentForm.submitSucceeded) return false;
  const isFormPristine = isPristine(formName)({
    form: allForms || { [formName]: form },
  });
  const isFormDirty = isDirty(formName)({
    form: allForms || { [formName]: form },
  });
  return isFormPristine ? false : isFormDirty;
};

export const isWarningAccepted = message => window.confirm(message); // eslint-disable-line no-alert

export const isIOS = () =>
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const isIOSServer = userAgent =>
  !!userAgent && /iPad|iPhone|iPod/.test(userAgent);

export const toggleCheckRow = (selected, item) => {
  const selectedIndex = _.findIndex(selected, { _id: item._id });
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, item);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }

  return newSelected;
};

export const parseSort = field => {
  let order = 'asc';
  let orderBy = field;
  if (orderBy && orderBy.indexOf('-') === 0) {
    order = 'desc';
    orderBy = orderBy.replace(/-/gi, '');
  }

  return { order, orderBy };
};

export const ssrRequest = async ({
  store,
  fetch,
  getAction,
  resetAction = null,
  context = {},
}) => {
  if (resetAction) {
    await store.dispatch(resetAction());
  }

  await store.dispatch({
    type: getAction().types[0],
  });

  let status;
  try {
    const res = await getAction().promise(fetch);
    status = res.status;

    const resText = await res.text();

    const json = resText ? JSON.parse(resText) : {};

    if (res.status !== 200) {
      if (res.status === 401) {
        return notAuthorized(context);
      }

      if (res.status === 403) {
        return notFound({
          ...context,
          status: res.status,
          title: json && json.message,
        });
      }

      await store.dispatch({
        error: json,
        type: getAction().types[2],
      });

      return notFound({
        ...context,
        status: res.status,
        title: json && json.message,
      });
    }

    const result = {
      status: res.status,
      total: Number.parseInt(res.headers.get('x-total-count'), 10) || 0,
      json,
    };

    return store.dispatch({
      result,
      type: getAction().types[1],
    });
  } catch (error) {
    console.error('MIDDLEWARE ERROR:', error);
    await store.dispatch({
      error,
      type: getAction().types[2],
    });

    return notFound({
      ...context,
      status,
      title: error,
    });
  }
};

export function strMapToObj(strMap) {
  const obj = Object.create(null);
  strMap.forEach((v, k) => {
    obj[k] = v;
  });
  return obj;
}

export const youtubeDurationToSeconds = d => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  // Remove PT from string ref: https://developers.google.com/youtube/v3/docs/videos#contentDetails.duration
  let duration = d.replace('PT', '');

  // If the string contains hours parse it and remove it from our duration string
  if (duration.indexOf('H') > -1) {
    const hoursSplit = duration.split('H');
    hours = parseInt(hoursSplit[0], 10);
    duration = hoursSplit[1];
  }

  // If the string contains minutes parse it and remove it from our duration string
  if (duration.indexOf('M') > -1) {
    const minutesSplit = duration.split('M');
    minutes = parseInt(minutesSplit[0], 10);
    duration = minutesSplit[1];
  }

  // If the string contains seconds parse it and remove it from our duration string
  if (duration.indexOf('S') > -1) {
    const secondsSplit = duration.split('S');
    seconds = parseInt(secondsSplit[0], 10);
  }

  // Math the values to return seconds
  return hours * 60 * 60 + minutes * 60 + seconds;
};

export const groupDatesBy = (dateFrom, dateTo) => {
  const rangeDiffDays = moment
    .range(new Date(dateFrom), new Date(dateTo))
    .diff('days');

  let groupBy;

  if (rangeDiffDays < 2) {
    groupBy = 'hour';
  } else if (rangeDiffDays <= 31) {
    groupBy = 'day';
  } else if (rangeDiffDays <= 31 * 3) {
    groupBy = 'week';
  } else {
    groupBy = 'month';
  }

  return groupBy;
};

export const isTourShown = (state, field) =>
  _.isUndefined(state.tours.isShown[field])
    ? isLoggedIn(state.auth) &&
      !_.get(state.auth, `user.isHelpButtonSeen.${field}`)
    : _.get(state.tours.isShown, field, false);

export const convertBreakLines = text =>
  typeof text === 'string' ? text.replace(/\n/g, '<br />') : '';

export const getMainGroupOption = (auth, option) =>
  _.get(auth, `user.mainGroup.options.${option}`);

export const isStatisticsDateRangeValid = ({ dateFrom, dateTo }) => {
  const maximumDateRange = {
    units: 'months',
    number: 18,
  };

  if (!dateFrom.isValid() || !dateTo.isValid()) {
    return false;
  }

  if (
    dateFrom.diff(dateTo) > 0 ||
    dateTo.diff(dateFrom, maximumDateRange.units) > maximumDateRange.number
  ) {
    return false;
  }

  return true;
};

export const handleErroredImage = e => {
  const img = `${window.App.cloudfrontUrl}/default.svg`;
  if (e.target.src !== img) {
    e.target.setAttribute('src', img);
  }
};

export const getMissingRequiredProfileFields = auth => {
  if (!isLoggedIn(auth)) {
    return [];
  }

  const { user } = auth;

  return ['profession', 'education', 'gender', 'yearBirth'].filter(
    item => !user[item],
  );
};

export const isTwoFaSetupRequired = auth =>
  _.get(auth, `user.twoFaSetupRequired`, false);

export const validateYoutubeVideoUrl = (value, { fetch }) => {
  const messages = defineMessages({
    invalidYouTubeOrVimeoUrl: {
      id: 'General.invalidYouTubeOrVimeoUrl',
      defaultMessage: 'Url does not match Vimeo and YouTube either',
    },
    videoIsNotEmbeddable: {
      id: 'General.videoIsNotEmbeddable',
      defaultMessage:
        "Video owner hasn't allow to embed this video outside of YouTube",
    },
    vimeoVideoIsNotEmbeddable: {
      id: 'General.vimeoVideoIsNotEmbeddable',
      defaultMessage: "Video owner hasn't allow to embed this video",
    },
  });

  const youtubeVideoUrl = _.trim(value);

  const { id, service } = getVideoId(`${youtubeVideoUrl}`);

  if (!['youtube', 'vimeo'].includes(service) || !id) {
    // eslint-disable-next-line no-throw-literal
    throw {
      youtubeVideoUrl: (
        <FormattedMessage {...messages.invalidYouTubeOrVimeoUrl} />
      ),
    };
  }

  if (service === 'youtube') {
    return fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${window.App.googleApiKey}&part=status`,
    )
      .then(response => response.json())
      .then(json => {
        const isEmbeddable = _.get(json, 'items.0.status.embeddable');

        if (!isEmbeddable) {
          // eslint-disable-next-line no-throw-literal
          throw {
            youtubeVideoUrl: (
              <FormattedMessage {...messages.videoIsNotEmbeddable} />
            ),
          };
        }
      });
  }

  return fetch(
    `https://vimeo.com/api/oembed.json?url=${encodeURI(youtubeVideoUrl)}`,
  ).then(response => {
    const error = {
      youtubeVideoUrl: (
        <FormattedMessage {...messages.vimeoVideoIsNotEmbeddable} />
      ),
    };
    if (!response.ok) {
      throw error;
    }
  });
};

const getVimeoUrlType = videoUrl => {
  const event = /https?:\/\/vimeo\.com\/event\/(\d+)$/;
  const eventMatches = event.exec(videoUrl);
  if (eventMatches && eventMatches[1]) {
    return 'event';
  }
  const primary = /https?:\/\/vimeo\.com\/(\d+)/;
  const matches = primary.exec(videoUrl);

  if (matches && matches[1]) {
    return 'primary';
  }
  const player = /https?:\/\/player\.vimeo\.com\/video\/(\d+)/;
  const playerMatches = player.exec(videoUrl);
  if (playerMatches && playerMatches[1]) {
    return 'player';
  }
  return 'primary';
};

export const getStreamChatUrl = async (
  videoUrl,
  { videoService, videoId, videoAppId },
  { fetch, vimeoLivestreamUrl },
) => {
  if (videoService === 'youtube') {
    return `https://www.youtube.com/live_chat?v=${videoId}/`;
  }
  if (videoService === 'vimeo') {
    const vimeoUrlType = getVimeoUrlType(videoUrl);
    if (vimeoUrlType === 'event') {
      let defaultChatUrl = `https://vimeo.com/event/${videoId}/chat/`;

      if (videoAppId) {
        defaultChatUrl = `https://vimeo.com/event/${videoId}/chat/${videoAppId}`;
      }

      try {
        const eoembded = await fetch(
          `https://vimeo.com/api/oembed.json?url=${encodeURI(
            vimeoLivestreamUrl,
          )}`,
        );
        const parsedEoembded = await eoembded.json();
        const chat = _.get(parsedEoembded, 'chat', '');
        const urlPattern = /src="(.*?)\/"/gm;
        const matches = urlPattern.exec(chat);
        if (matches && matches[1]) {
          const urlMatch = matches[1];
          const replacedUrl = urlMatch.replace(/\/([0-9]+)\//, `/${videoId}/`);
          if (videoAppId) {
            return `${replacedUrl}/${videoAppId}`;
          }
          return replacedUrl;
        }
        return defaultChatUrl;
      } catch (err) {
        return defaultChatUrl;
      }
    }
    if (vimeoUrlType === 'primary' || vimeoUrlType === 'player') {
      if (videoAppId) {
        return `https://vimeo.com/live-chat/${videoId}/interaction/${videoAppId}`;
      }
      return `https://vimeo.com/live-chat/${videoId}/interaction/`;
    }
  }

  return '';
};

export const getVimeoAppId = url => {
  const primary = /https?:\/\/vimeo\.com\/([0-9]+)\/([0-9A-Za-z]+)/;

  const matches = primary.exec(url);

  if (matches && matches[2]) {
    return matches[2];
  }

  return '';
};

export const isGloballyFreeLessonAvailable = ({ auth, whitelabeling }) => {
  if (isAdmin(auth)) {
    return true;
  }

  if (!isWhitelabeledOrganizationAdmin(auth)) {
    return false;
  }

  return !!_.get(whitelabeling, 'isGloballyFreeLessonAvailable');
};

export const convertRichEditorTextToHtml = value => {
  if (!value) {
    return '';
  }
  const rawText = _.assign({}, emptyContent, value);
  const editorState = EditorState.createWithContent(convertFromRaw(rawText));

  const editorStateContent = editorState && editorState.getCurrentContent();
  return stateToHTML(editorStateContent);
};

export const hasMedicalLicense = mainGroup => {
  if (!isWhitelabeledOrganization(mainGroup)) {
    return false;
  }

  return (
    _.get(mainGroup, 'whitelabelingOptions.isMedicalLicenseOrganization') ||
    _.get(
      mainGroup,
      'mainGroup.whitelabelingOptions.isMedicalLicenseOrganization',
    ) ||
    false
  );
};

export const onlyExamsWithCode = mainGroup => {
  if (!isWhitelabeledOrganization(mainGroup)) {
    return false;
  }

  return (
    _.get(mainGroup, 'whitelabelingOptions.onlyExamsWithCode') ||
    _.get(mainGroup, 'mainGroup.whitelabelingOptions.onlyExamsWithCode') ||
    false
  );
};

export const convertRichEditorContentToHtml = (value, cloudfrontUrl) => {
  if (!value) {
    return {
      isTextExist: false,
      contentHTML: '',
      textLength: 0,
    };
  }
  const editorState = createEditorState(value);
  const editorStateContent = editorState && editorState.getCurrentContent();
  const isTextExist =
    editorStateContent &&
    editorStateContent.hasText() &&
    !!editorStateContent.getPlainText().trim().length;

  const stateToHtmlOptions = {
    entityStyleFn: entityStyleFn({ cloudfrontUrl }),
  };

  const contentHTML = stateToHTML(editorStateContent, stateToHtmlOptions);

  return {
    isTextExist,
    contentHTML,
    textLength: isTextExist ? editorStateContent.getPlainText().length : 0,
  };
};

export const convertFromDraftToPlain = (editorState, delimiter) =>
  editorState.getCurrentContent().getPlainText(delimiter);

export const getSystemMessageTitle = (message, locale) => {
  if (!message) return '';
  return _.get(message, `name.${locale}`)
    ? message.name[locale]
    : message.name['en-US'];
};

export const getSystemMessageBody = (message, locale) => {
  if (!message) return '';

  const preparedMessage = _.get(message, `message.${locale}`);
  if (!preparedMessage)
    return convertRichEditorTextToHtml(message.message['en-US']);

  const editorState = EditorState.createWithContent(
    preparedMessage ? convertFromRaw(preparedMessage) : emptyContentState,
  );
  const editorStateContent = editorState && editorState.getCurrentContent();
  if (!editorStateContent || !editorStateContent.hasText()) {
    return convertRichEditorTextToHtml(message.message['en-US']);
  }

  return convertRichEditorTextToHtml(message.message[locale]);
};

export const getTrialPageUrl = lang => {
  const url = window.App.trialPageUrls[lang];

  if (!url) {
    return window.App.trialPageUrls['en-US'];
  }

  return url;
};

export const isIntegrationPersonIdVerificationEnabled = user => {
  if (!isUserFromWhitelabeledOrganization(user)) {
    return false;
  }

  return (
    _.get(
      user,
      'user.mainGroup.whitelabelingOptions.integrationPersonIdVerificationEnabled',
      false,
    ) ||
    _.get(
      user,
      'user.mainGroup.mainGroup.whitelabelingOptions.integrationPersonIdVerificationEnabled',
      false,
    )
  );
};

export const isIntegrationPersonIdValid = user =>
  _.get(user, 'user.integrationPersonId') &&
  _.get(user, 'user.isIntegrationPersonIdValid');

export const normalizeSlug = value => {
  if (!value) {
    return value;
  }

  return slugify(value.toLowerCase());
};

export const getImagesFromDraftJS = content =>
  _(_.toArray(_.get(content, 'entityMap', {})))
    .filter(({ type }) => type === 'IMAGE')
    .map(item => _.get(item, 'data.src', '').replace('/cloudfront/', ''))
    .filter(item => !!item)
    .map(path => ({ path }))
    .value();

export const isWhitelabelingTeacherSeesOnlyOwnGroup = user => {
  if (!isUserFromWhitelabeledOrganization(user)) {
    return false;
  }

  return (
    _.get(
      user,
      'user.mainGroup.whitelabelingOptions.teacherSeesOnlyOwnGroup',
      false,
    ) ||
    _.get(
      user,
      'user.mainGroup.mainGroup.whitelabelingOptions.teacherSeesOnlyOwnGroup',
      false,
    )
  );
};

export const hideAddGroupBtnForTeachers = user => {
  if (
    !isUserFromWhitelabeledOrganization(user) ||
    _.get(user, 'user.role') !== userRole.TEACHER
  ) {
    return false;
  }

  return (
    _.get(
      user,
      'user.mainGroup.whitelabelingOptions.hideAddGroupBtnForTeachers',
      false,
    ) ||
    _.get(
      user,
      'user.mainGroup.mainGroup.whitelabelingOptions.hideAddGroupBtnForTeachers',
      false,
    )
  );
};

export const getOrganizationAvatar = (mainGroup, cloudfrontUrl) => {
  const whitelabedOptions =
    _.get(mainGroup, 'whitelabelingOptions') ||
    _.get(mainGroup, 'mainGroup.whitelabelingOptions');

  return _.get(whitelabedOptions, `favicon32Url`)
    ? `${cloudfrontUrl}${_.get(whitelabedOptions, `favicon32Url`)}`
    : '/favicon-32x32.png';
};

export const isAllowedToTeacherCreateBundleCert = (auth, user) => {
  if (!isLoggedIn(auth)) {
    return false;
  }
  const isMainGroupLeader =
    _.get(auth.user, 'mainGroupId', '') === user.mainGroupId;

  const isTeacherSeesOnlyOwnGroup =
    _.get(auth, 'user.role') === userRole.TEACHER &&
    isWhitelabelingTeacherSeesOnlyOwnGroup(auth);

  const isAllowedToTeacherCreateCertCollection = isTeacherSeesOnlyOwnGroup
    ? _.get(auth.user, 'subgroupIds.length') &&
      !!_.intersection(
        auth.user.subgroupIds,
        _.map(_.get(user, 'subgroupIds'), '_id'),
      ).length
    : _.get(auth.user, 'mainGroupId', '') === user.mainGroupId;

  return (
    (isAdmin(auth) && user.role !== userRole.ADMIN) ||
    (isGroupLeader(auth) &&
      [
        userRole.STUDENT,
        userRole.TEACHER,
        userRole.GROUP_LEADER,
        userRole.GROUP_MANAGER,
      ].includes(user.role) &&
      isMainGroupLeader) ||
    (isTeacher(auth) &&
      [userRole.STUDENT, userRole.TEACHER].includes(user.role) &&
      isAllowedToTeacherCreateCertCollection)
  );
};

export const isMandatoryGroupInvitation = user => {
  if (!isUserFromWhitelabeledOrganization(user)) {
    return false;
  }

  return (
    _.get(
      user,
      'user.mainGroup.whitelabelingOptions.mandatoryGroupInvitation',
      false,
    ) ||
    _.get(
      user,
      'user.mainGroup.mainGroup.whitelabelingOptions.mandatoryGroupInvitation',
      false,
    )
  );
};

export const canUserAssignTaskToTheWholeOrg = auth => {
  if (
    _.get(auth, 'user.role') === userRole.TEACHER &&
    isWhitelabelingTeacherSeesOnlyOwnGroup(auth)
  ) {
    return false;
  }

  if (isTeacher(auth)) {
    return true;
  }
  return false;
};

export const isMainGroupApiClient = mainGroup =>
  _.get(mainGroup, 'isApiClient', false);

export const userSkillEvaluationEnabled = user => {
  if (!isUserFromWhitelabeledOrganization(user)) {
    return false;
  }

  return (
    _.get(
      user,
      'user.mainGroup.whitelabelingOptions.userSkillEvaluationEnabled',
    ) ||
    _.get(
      user,
      'user.mainGroup.mainGroup.whitelabelingOptions.userSkillEvaluationEnabled',
    )
  );
};

export const isSelfJoinPendingUser = user =>
  !user.activated && !!user.addToNewOrganizationToken;

export const getContentCategoriesForFilter = auth => {
  const categories = auth?.user?.mainGroup?.contentCategories?.filter(
    item => !item.isGeneral,
  );

  // if categories contains at least one general category - do not show category filter at all
  if (
    !!categories &&
    categories.length !== auth.user.mainGroup.contentCategories.length
  ) {
    return null;
  }
  return categories?.map(item => item._id) || [];
};

export const isUserMainGroupNonHealthCare = auth => {
  const user = auth?.user;
  if (!user) {
    return false;
  }

  if (user.role === userRole.ADMIN) {
    return false;
  }
  const categories = user?.mainGroup?.contentCategories?.filter(
    item => !item.isGeneral,
  );

  // if categories contains at least one general category - then mainGroup is non-healthcare
  if (
    !!categories &&
    categories.length !== user.mainGroup.contentCategories.length
  ) {
    return true;
  }
  return false;
};

export const isUserHasExtendedEditorRights = auth =>
  _.get(auth, 'user.mainGroup.allowEditorsEditAccessiblePublicCourses');

export const isTwoFaVerified = auth => {
  if (!_.get(auth, 'user.otpEnabled')) {
    return false;
  }
  if (!_.get(auth, 'user.otpVerified')) {
    return false;
  }

  return true;
};

export const isFormsAndDocumentsAllowedForOrganization = mainGroup =>
  mainGroup?.formsAndDocuments || false;

export const canUserManageForms = auth => {
  if (isAdmin(auth)) {
    return true;
  }

  if (
    isWhitelabeledOrganizationAdmin(auth) &&
    auth?.user?.childOrgDocumentsAndFormsAllowed
  ) {
    return true;
  }

  if (!isFormsAndDocumentsAllowedForOrganization(auth?.user?.mainGroup)) {
    return false;
  }

  return !!(isGroupLeader(auth) || auth?.user?.isFormCreator);
};

export const isAllowedToTeacherUserDocument = (auth, user) => {
  if (!isLoggedIn(auth)) {
    return false;
  }
  const isMainGroupLeader =
    _.get(auth.user, 'mainGroupId', '') === user.mainGroupId;

  const isTeacherSeesOnlyOwnGroup =
    _.get(auth, 'user.role') === userRole.TEACHER &&
    isWhitelabelingTeacherSeesOnlyOwnGroup(auth);

  const isAllowedToTeacherCreateDocument = isTeacherSeesOnlyOwnGroup
    ? _.get(auth.user, 'subgroupIds.length') &&
      !!_.intersection(
        auth.user.subgroupIds,
        _.map(_.get(user, 'subgroupIds'), '_id'),
      ).length
    : _.get(auth.user, 'mainGroupId', '') === user.mainGroupId;

  return (
    isAdmin(auth) ||
    (isGroupLeader(auth) &&
      [
        userRole.STUDENT,
        userRole.TEACHER,
        userRole.GROUP_LEADER,
        userRole.GROUP_MANAGER,
      ].includes(user.role) &&
      isMainGroupLeader) ||
    (isTeacher(auth) &&
      [userRole.STUDENT, userRole.TEACHER].includes(user.role) &&
      isAllowedToTeacherCreateDocument)
  );
};

export const isWhitelabeledAdminCanManageUserForms = (auth, user) =>
  isWhitelabeledOrganizationAdmin(auth) &&
  auth?.user?.childOrgDocumentsAndFormsAllowed &&
  auth?.user?.childOrgsWithAllowedFormsAndDocuments.includes(user?.mainGroupId);

export const isDemonstrationChangeActionDisabled = (auth, demonstration) => {
  if (isAdmin(auth)) {
    return false;
  }

  return isWhitelabeledOrganizationAdmin(auth)
    ? false
    : demonstration.mainGroupId !== auth.user.mainGroupId;
};
