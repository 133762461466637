// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-no-translate]{translate:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.uRDCZ{bottom:10px;position:absolute;right:10px}@media only screen and (max-width: 767px){.uRDCZ{bottom:initial;margin:8px 0 0;position:relative;right:initial;width:initial}}@media only screen and (max-width: 767px)and (orientation: landscape){.uRDCZ{bottom:25px;position:fixed;right:15px;width:15%}}@media only screen and (max-width: 767px){.uRDCZ>button{background-color:var(--whitelabeling-primary-color);border-radius:3px;color:#fff;font-size:15px;height:36px;width:100%}.uRDCZ>button svg{display:none}}@media(max-width: 767px)and (orientation: landscape){.wbrvy{bottom:initial;position:relative;right:initial;width:100%}}.tvqGK>button{background-color:#4caf50}@media only screen and (max-width: 767px){.tvqGK>button{background-color:#4caf50 !important}}@media(min-width: 768px)and (max-width: 991px){.xz5FD{display:none}}@media(min-width: 992px){.xz5FD{display:none}}.q1G9y{position:absolute;right:5px;top:5px}@media only screen and (max-width: 767px){.q1G9y>button{background-color:rgba(0,0,0,0);-webkit-box-shadow:none;box-shadow:none;color:#959591}.q1G9y>button:hover,.q1G9y>button:active{background-color:rgba(0,0,0,0);-webkit-box-shadow:none;box-shadow:none}}@media(orientation: landscape)and (max-width: 823px){.q1G9y.ZMJqv>button{background-color:rgba(0,0,0,0);-webkit-box-shadow:none;box-shadow:none;color:#959591}.q1G9y.ZMJqv>button:hover,.q1G9y.ZMJqv>button:active{background-color:rgba(0,0,0,0);-webkit-box-shadow:none;box-shadow:none}}.B0Xl6{background-color:rgba(0,0,0,0);-webkit-box-shadow:none;box-shadow:none;color:#959591}.B0Xl6:hover,.B0Xl6:active{background-color:rgba(0,0,0,0);-webkit-box-shadow:none;box-shadow:none}.W1mCA{color:#4caf50;left:-6px;position:absolute;top:-6px;z-index:1}@media only screen and (max-width: 767px){.W1mCA{left:calc(50% - 26px);top:-8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveBtn": "uRDCZ",
	"mobileLandscapeRelative": "wbrvy",
	"successed": "tvqGK",
	"displayOnlyMobile": "xz5FD",
	"closeModalBtn": "q1G9y",
	"landscapeMobile": "ZMJqv",
	"mobileViewCloseButton": "B0Xl6",
	"fabProgress": "W1mCA"
};
module.exports = ___CSS_LOADER_EXPORT___;
